import React, { useState, useMemo, useRef } from "react";

import debounce from "lodash/debounce";
import { Select, Spin, Col, Form, Input } from "antd";
import { notifyWarning } from "../../../utils/notify";

export default function SelectAutocomplete({
  fetchOptions,
  debounceTimeout = 800,
  span = 24,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const { max, label, name, rules, onChange } = props;

  const handleShowError = (e) => {
    notifyWarning(
      "Warning",
      `You have selected maximum entries for this input, deselect to change.`
    );
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Col span={span}>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) => {
          return prevValues[name] !== curValues[name];
        }}
      >
        {({ getFieldValue }) => {
          const value = getFieldValue(name);
          const isMax = value && value.length == max;

          return (
            <Form.Item name={name} label={label} rules={rules}>
              <Select
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
                {...(isMax && {
                  open: false,
                  onDropdownVisibleChange: handleShowError,
                })}
                onChange={onChange}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </Col>
  );
}
