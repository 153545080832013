import React from "react";

import { useQuery } from "react-query";

import { getFileMetaList } from "../../api/files";
import {getBaseUrl, getJwtToken} from "../../utils/auth-util";

import { Card, Col, Row, Button, List, Modal, Spin } from "antd";
import { StarOutlined } from "@ant-design/icons";

const download = [
  <svg
    width="15"
    height="15"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="0"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 9.29289C6.68342 8.90237 7.31658 8.90237 7.70711 9.29289L9 10.5858L9 3C9 2.44772 9.44771 2 10 2C10.5523 2 11 2.44771 11 3L11 10.5858L12.2929 9.29289C12.6834 8.90237 13.3166 8.90237 13.7071 9.29289C14.0976 9.68342 14.0976 10.3166 13.7071 10.7071L10.7071 13.7071C10.5196 13.8946 10.2652 14 10 14C9.73478 14 9.48043 13.8946 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289Z"
      fill="#111827"
    ></path>
  </svg>,
];

const BASE_URL = getBaseUrl();

export default function ImportantDownload() {
  const { data, isLoading } = useQuery(["metas", "DOWNLOAD"], getFileMetaList);

  if (isLoading) {
    return <Spin size="large" />;
  }

  return (
    <Card
      bordered={false}
      className="header-solid h-full ant-invoice-card mb-2"
      title={[
        <h6 key="title" className="font-semibold m-0">
          Important Downloads
        </h6>,
      ]}
      extra={[<StarOutlined key="star" />]}
    >
      <List
        itemLayout="horizontal"
        className="invoice-list"
        dataSource={data}
        renderItem={(item) => (
            <List.Item
                key={item.id}
                actions={[
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`#`}
                        onClick={(e) => {
                            e.preventDefault();
                            const token = getJwtToken() // Replace with your actual authorization token
                            const headers = new Headers();
                            headers.append("Authorization", `Bearer ${token}`);
                            const downloadUrl = `${BASE_URL}/files/${item.relativePath}`;
                            fetch(downloadUrl, { headers })
                                .then((response) => {
                                    if (response.ok) {
                                        window.open(downloadUrl, "_blank");
                                    } else {
                                        console.error("Failed to download file");
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error occurred while downloading file:", error);
                                });
                        }}
                    >
                        {download} {item.extension}
                    </a>,
                ]}
            >
            <List.Item.Meta title={item.name} description={item.description} />
          </List.Item>
        )}
      />
    </Card>
  );
}
