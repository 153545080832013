import moment from "moment";
import { format } from "../utils/money";

const formatStatement = (data) => {
  var transactionList = [];
  transactionList.push([
    {
      text: "Trx Date",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
    {
      text: "Trx No",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
    {
      text: "Narration",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
    {
      text: "Debit",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
    {
      text: "Credit",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
    {
      text: "A/C Balance",
      style: "tableHeader",
      margin: [0, 4, 0, 4],
    },
  ]);
  console.log(transactionList);

  var transactions = data.transactionList.map(function (transaction, i) {
    return [
      {
        text: transaction.trxDateH,
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
      {
        text: transaction.trxNo,
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
      {
        text: transaction.narration,
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
      {
        text: format(transaction.debit),
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
      {
        text: format(transaction.credit),
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
      {
        text: format(transaction.balance),
        style: "normalString",
        margin: [0, 4, 0, 4],
      },
    ];
  });
  Array.prototype.push.apply(transactionList, transactions);

  var dd = {
    content: [
      {
        text: moment(data.date).format("MMMM Do YYYY, h:mm:ss a"),
        style: "normalString",
        alignment: "right",
      },
      {
        text: data.companyName,
        style: "subHeader",
        alignment: "center",
      },
      {
        text: "Account Statement",
        style: "header",
        alignment: "center",
        margin: [0, 8, 0, 2],
      },
      {
        style: "infoStyle",
        table: {
          widths: [90, 180],
          body: [
            [
              {
                text: "Account Name",
                style: "normalString",
              },
              {
                text: data.accountName,
                style: "normalString",
              },
            ],
            [
              {
                text: "Account Type",
                style: "normalString",
              },
              {
                text: data.productName,
                style: "normalString",
              },
            ],
            [
              {
                text: "Open Balance",
                height: 250,
                style: "normalString",
              },
              {
                text: format(data.openBalance),
                style: "normalString",
              },
            ],
            [
              {
                text: "Total Debit",
                height: 250,
                style: "normalString",
              },
              {
                text: format(data.totalDebit),
                style: "normalString",
              },
            ],
            [
              {
                text: "Total Credit",
                height: 250,
                style: "normalString",
              },
              {
                text: format(data.totalCredit),
                style: "normalString",
              },
            ],
          ],
        },
      },
      {
        style: "transactionStyle",
        table: {
          widths: ["15%", "10%", "30%", "15%", "15%", "15%"],
          body: transactionList,
        },
      },
    ],
    styles: {
      header: {
        fontSize: 11,
        bold: true,
        margin: [0, 0, 0, 10],
        align: "center",
      },
      subheader: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
      },
      infoStyle: {
        margin: [210, 40, 0, 15],
        layout: {
          hLineColor: "red",
          vLineColor: "red",
        },
      },
      transactionStyle: {
        margin: [0, 40, 0, 15],
        layout: {
          hLineColor: "red",
          vLineColor: "red",
        },
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        fillColor: "#cccccc",
      },
      disbursementStyle: {
        bold: false,
        fontSize: 8,
        fillColor: "#80ff80",
      },
      rescheduleStyle: {
        bold: false,
        fontSize: 8,
        fillColor: "#ffff66",
      },
      tableContent: {
        bold: true,
        fontSize: 10,
        color: "black",
      },
      normalString: {
        bold: false,
        fontSize: 9,
        color: "black",
      },
      totals: {
        bold: true,
        fontSize: 8,
        color: "black",
      },
    },
    footer: {},
    pageSize: "a4",
    defaultStyle: {},
    pageOrientation: "potrait",
  };

  return dd;
};

export default formatStatement;
