import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Col,
  Select,
  Form,
  Input,
  Button,
  Tag,
  Radio,
  Image,
  Card,
  Menu,
  Dropdown,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import map from "lodash/map";
import startWith from "lodash/startsWith";
import styled from "styled-components";

import { getFileMetaList } from "../../api/files";
import { getBaseUrl } from "../../utils/auth-util";
import DocTile from "./doc-tile";

const { useForm } = Form;

const { Option } = Select;

const BASE_URL = getBaseUrl();

export default function FileExplorer({ show, handleShow, type, title }) {
  const { data, isLoading } = useQuery(["metas"], getFileMetaList);

  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"60VW"}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[
          <Button
            form="FileExplorerForm"
            type="default"
            key="cancel"
            onClick={() => handleShow(false)}
            htmlType="button"
          >
            Cancel
          </Button>,
          <Button
            form="FileExplorerForm"
            type="primary"
            key="save"
            htmlType="submit"
          >
            Save
          </Button>,
        ]}
      >
        <Row>
          {map(data, (doc) => (
            <DocTile baseUrl={BASE_URL} key={doc.id} {...doc} />
            // <Card title={doc.fileName} style={{ width: 250 }}>

            //   <Row>
            //     <Button>Downloads</Button>
            //   </Row>
            // </Card>
          ))}
        </Row>
      </Modal>
    </>
  );
}
