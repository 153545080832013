/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {Row, Col, Card, Button, Form, Select, InputNumber,message, Input} from "antd";
import {useState} from "react";
import map from "lodash/map";
import {
    getLoanProducts,
    postLoanApplication, searchApprovers,
} from "../../api/accounts";
import {useTheme, useUserData} from "../../hooks";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {notifyWarning} from "../../utils/notify";
import {useMutation, useQuery} from "react-query";
import SelectableApprover from "../../components/widgets/event-guide/SelectableApprover";
import FileUpload from "../../components/widgets/FileUpload";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const {Option} = Select;

const {useForm} = Form;

function LoanApplication() {
    const [isLoading, setLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [form] = useForm();
    const {theme: {primaryColor, secondaryColor}} = useTheme();


    const {error: productError, data: products} = useQuery(
        "loan-products",
        getLoanProducts
    );

    if (productError) {
        notifyWarning("Error on loading loan products!");
    }


    const loanApplication = useMutation(postLoanApplication, {
        onSuccess: (data) => {
            if(data.detail){
                message.warn(data.detail);
            }else {
                if(data.statusCode === "00"){
                    message.success(data.message);
                    reset();
                }else {
                    message.warn(data.message);
                }

            }
            setLoading(false);
        },
        onError: (e) => {
            message.error(e.message);
            setLoading(false);
            console.log("error", e);
        },
        onSettled: (s) => {
            setLoading(false);
        },
    });

    const reset = () => {
        form.resetFields();
        setSelectedProduct(null);
    };


    const handleSelectProduct = (value) => {
        if (products !== undefined) {
            setSelectedProduct(products.find(product => product.glNo === value));
        }
    };


    const onFinish = (values) => {
        const attachments = selectedProduct?.attachments.map(attachment=> {
            return {
                label: attachment.label,
                role: attachment.role,
                id: values[`${attachment.role}_id`]
            }
        });



        const guarantors= selectedProduct?.guarantors.map((guarantor,index)=>{
            console.log(index)
            return {
                label: guarantor.label,
                role: guarantor.role,
                value: values.loanGuarantors[index].value
            }
        })

        const request = {
            glNo: selectedProduct?.glNo,
            lnPeriod: values.loanPeriod,
            loanAmt: values.loanAmount,
            guarantors,
            attachments
        };


        setLoading(true);
        // delete request.approvers;
        loanApplication.mutate(request);
    };

    const onChange = (values) => {
        // setLoading(true);
    };
    return (
        <>
            <Row gutter={[24, 0]}>
                <Col xs={24} md={16}>
                    <Row gutter={[24, 0]}>
                        {" "}
                        <Col span={24} md={24} className="mb-24">
                            <Card
                                bordered={false}
                                bodyStyle={{paddingTop: 0}}
                                className="header-solid h-full  ant-list-yes"
                                title={<h6 className="font-semibold m-0">Loan Application</h6>}
                                extra={<p className="card-header-date"></p>}
                            >
                                <Form
                                    labelCol={{span: 24}}
                                    wrapperCol={{span: 24}}
                                    autoComplete="off"
                                    onFinish={onFinish}
                                    form={form}
                                    onChange={(e) => {
                                        console.log("something changed", e);
                                    }}
                                    // initialValues={{ amount: 0, durationCount: 1, ...service }}
                                >
                                    <Row justify="space-between">
                                        <Col span={24}>
                                            {/* payment modality field */}
                                            <Form.Item
                                                name="glNo"
                                                label="Product"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Product is required",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select Product"
                                                    size="large"
                                                    value={selectedProduct}
                                                    onChange={(value) => handleSelectProduct(value)}
                                                    allowClear
                                                >
                                                    {map(products, (account) => (
                                                        <Option value={account.glNo} key={account.glNo}>
                                                            {account.productName}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            {/* end payment modality field */}
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item name="loanAmount" label="Loan Amount"
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: "Loan Amount is required",
                                                           },
                                                       ]}>
                                                <InputNumber
                                                    style={{width: "100%"}}
                                                    formatter={(value) =>
                                                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                    }
                                                    parser={(value) => value.replace(/TZS\s?|(,*)/g, "")}
                                                    onChange={onChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name="loanPeriod" label="Loan Period"
                                                       rules={[
                                                           {
                                                               required: true,
                                                               message: "Loan Period is required",
                                                           },
                                                       ]}>
                                                <Input/>
                                            </Form.Item>
                                        </Col>
                                        {selectedProduct?.guarantors &&selectedProduct.guarantors.length > 0 && (<SelectableApprover
                                            key={"guarantors"}
                                            mode="multiple"
                                            name={'loanGuarantors'}
                                            label={`Loan Guarantors`}
                                            placeholder={`Type to search Guarantors`}
                                            max={selectedProduct?.guarantors.length}
                                            step={'1'}

                                            fetchOptions={async (name) => {
                                                const approvers = await searchApprovers(name);
                                                return approvers.map(value => {
                                                    return {
                                                        label: value.name,
                                                        value: value.custNo,
                                                        key: value.custNo,
                                                    }
                                                });
                                            }}
                                            style={{
                                                width: "100%",
                                            }}
                                        />)
                                        }
                                        {selectedProduct?.attachments.map(attachment => {


                                            return (
                                                <FileUpload
                                                    form={form}
                                                    label={attachment.label}
                                                    key={`${attachment.role}_upload`}
                                                    name={attachment.role}
                                                />

                                            );
                                        })}

                                    </Row>

                                    {/* form actions */}
                                    <Form.Item
                                        wrapperCol={{span: 24}}
                                        style={{textAlign: "right"}}
                                    >
                                        <Button
                                            onClick={() => reset()}
                                            style={{
                                                marginLeft: 16,
                                                paddingLeft: '40px',
                                                paddingRight: '40px',
                                            }}
                                        >
                                            Clear
                                        </Button>
                                        <Button
                                            style={{
                                                marginLeft: 8,
                                                backgroundColor: primaryColor,
                                                color: secondaryColor,
                                                paddingLeft: '40px',
                                                paddingRight: '40px',
                                            }}
                                            htmlType="submit"
                                            loading={isLoading}
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                    {/* end form actions */}
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default LoanApplication;
