/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {Switch, Route, Redirect} from "react-router-dom";
import Home from "./pages/Home";
import AccountActivities from "./pages/AccountActivities";
import LoanApplication from "./pages/loans/LoanApplication";
import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Approvals from "./pages/Approvals";
import Main from "./components/layout/Main";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import {AuthProvider, UserProvider, ThemeProvider} from "./providers";
import {SecureRoute} from "./components";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import UserManagement from "./pages/UserManagement";
import ContentManager from "./pages/ContentManager";
import AttachmentManager from "./pages/AttachmentManager";
import ContentPublisher from "./pages/ContentPublisher";
import News from "./pages/News";
import ComingSoon from "./pages/ComingSoon";
import Customization from "./pages/Customization";
import {ChangePassword} from "./components/widgets";
import LoanStatus from "./pages/loans/LoanStatus";
import LoanGuarantees from "./pages/loans/LoanGuarantees";

const queryClient = new QueryClient({
    defaultOptions: {queries: {refetchOnWindowFocus: false}},
});

function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <ThemeProvider>
                    <UserProvider>
                        <AuthProvider>
                            <Switch>
                                {/* <Route path="/sign-up" exact component={SignUp} /> */}
                                <Route path="/sign-in" exact component={SignIn}/>
                                <Main>
                                    <SecureRoute exact path="/" component={Home}/>
                                    <SecureRoute
                                        exact
                                        path="/accounts/activities"
                                        component={AccountActivities}
                                    />

                                    {/*<SecureRoute*/}
                                    {/*  exact*/}
                                    {/*  path="/loans/application"*/}
                                    {/*  component={LoanApplication}*/}
                                    {/*/>*/}
                                    <SecureRoute exact path="/approvals" component={Approvals}/>
                                    <SecureRoute exact path="/profile" component={Profile}/>
                                    <SecureRoute
                                        exact
                                        path="/cms/editor"
                                        component={ContentManager}
                                    />
                                    <SecureRoute
                                        exact
                                        path="/cms/editor/:id"
                                        component={ContentManager}
                                    />


                                    <SecureRoute
                                        exact
                                        path="/news"
                                        component={News}
                                    />
                                    <SecureRoute
                                        exact
                                        path="/loans/application"
                                        component={LoanApplication}
                                    />

                                    <SecureRoute
                                        exact
                                        path="/loans/status"
                                        component={LoanStatus}
                                    />

                                    <SecureRoute
                                        exact
                                        path="/loans/guarantees"
                                        component={LoanGuarantees}
                                    />

                                    <SecureRoute
                                        exact
                                        path="/admin/customization"
                                        component={Customization}
                                    />


                                    <SecureRoute
                                        exact
                                        path="/news/:id"
                                        component={News}
                                    />
                                    <SecureRoute
                                        exact
                                        path="/cms/attachment"
                                        component={AttachmentManager}
                                    />

                                    <SecureRoute
                                        exact
                                        path="/cms/content-creator"
                                        component={ContentPublisher}
                                    />
                                    <SecureRoute
                                        exact
                                        path="/admin/user-management"
                                        component={UserManagement}
                                    />
                                    {/* <Redirect from="*" to="/" /> */}
                                </Main>
                            </Switch>
                        </AuthProvider>
                    </UserProvider>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false}/>
            </QueryClientProvider>
        </div>
    );
}

export default App;
