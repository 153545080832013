import React, { useState, useEffect } from "react";
import map from "lodash/map";
import moment from "moment";
import { useQuery } from "react-query";
import { getAccounts } from "../../api/accounts";
import { notifyWarning } from "../../utils/notify";
import statementDefiner from "../../services/statement-data-definer";
import { PdfMakeModal } from "../widgets";

import {
  Row,
  Col,
  Button,
  Form,
  Select,
  DatePicker,
  Skeleton,
} from "antd";
import {useTheme} from "../../hooks";

const { Option } = Select;

export default function AccountStatementForm() {
  const [show, setShow] = useState(false);
  const [statementRequest, setStatementRequest] = useState(null);
  const mq = window.matchMedia("(max-width: 500px)");
  const isMobile = mq.matches;
  const { isLoading, error, data, isFetching } = useQuery(
    "accounts",
    getAccounts
  );
  const {theme: {primaryColor}} = useTheme();

  if (isLoading) return <Skeleton active />;

  const onFinish = (values) => {
    if (!values.startDate || !values.endDate) {
      notifyWarning("Warning !", "Please select date range");
      return;
    }

    let { product, startDate, endDate } = values;
    const duration = moment.duration(endDate.diff(startDate));
    if (duration.asYears() > 1) {
      notifyWarning("Warning !", "Date range should not exceed 1 year");
      return;
    }

    setStatementRequest({
      product,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    });

    handleShow(true);
  };
  const onChange = (values) => {
    // setLoading(true);
  };

  const handleShow = (visible) => {
    setShow(visible);
  };

  if (error) return "An error has occurred: " + error.message;
  return (
    <>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        onFinish={onFinish}
      >
        <PdfMakeModal
          statementRequest={statementRequest}
          show={show}
          title="Account Statement"
          handleShow={handleShow}
        />
        <Row gutter={[24, 0]} justify="space-between">
          <Col span={24}>
            <Form.Item name="startDate" label="Start Date">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="endDate" label="End Date">
              <DatePicker
                size="large"
                style={{ width: "100%" }}
                onChange={onChange}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            {/* payment modality field */}
            <Form.Item
              name="product"
              label="Product"
              rules={[
                {
                  required: true,
                  message: "Product is required",
                },
              ]}
            >
              <Select placeholder="Select Product" size="large" allowClear>
                {map(data, (account) => (
                  <Option value={account.glNo} key={account.glNo}>
                    {account.productName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {/* end payment modality field */}
          </Col>
        </Row>

        {/* form actions */}
        <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: "right" }}>
          <Button
            style={{ marginLeft: 8, backgroundColor: primaryColor }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {isMobile ? "Download" : "Preview"}
          </Button>
        </Form.Item>
        {/* end form actions */}
      </Form>
    </>
  );
}
