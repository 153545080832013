import React from "react";
import { Col, Form } from "antd";
import ScaleAutocomplete from "./ScaleAutocomplete";

function ScaleAutoCompleteInput({ name, label, span, rules, hidden, ...rest }) {
  return (
    <Col span={span}>
      <Form.Item name={name} label={label} rules={rules}>
        <ScaleAutocomplete {...rest} />
      </Form.Item>
    </Col>
  );
}

export default ScaleAutoCompleteInput;
