import React, { useState } from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

function ColorPickerButton({ small = false, color, colorPicked }) {
  const [show, setShow] = useState(false);

  const width = small ? "25px" : "120px";
  const height = small ? "25px" : "120px";
  const borderRadius = small ? "0px" : "2px";
  const swatchPadding = small ? "0px" : "4px";

  const styles = reactCSS({
    default: {
      color: {
        width,
        height,
        borderRadius,
        background: color,
      },
      swatch: {
        padding: swatchPadding,
        background: "#fff",
        borderRadius: "2px 0px 0px 2px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
        with: "300px",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  return (
    <div>
      <div style={styles.swatch} onClick={() => setShow(true)}>
        <div style={styles.color} />
      </div>
      {show ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setShow(false)} />
          <SketchPicker color={color} onChange={colorPicked} />
        </div>
      ) : null}
    </div>
  );
}

export default ColorPickerButton;
