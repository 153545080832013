/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Layout, Row, Col } from "antd";

function Footer({ backgroundColor }) {
  const { Footer: AntFooter } = Layout;
  const currentYear = new Date().getFullYear();


  return (
    <AntFooter style={{ background: backgroundColor }}>
      <hr />
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div style={{ paddingLeft: 60 }} className="copyright">
            &copy; {currentYear} Copyright
            <a
                href="https://www.scalefi.co.tz/"
                className="font-weight-bold"
                target="_blank"
                rel="noopener noreferrer"
            >
              ScaleFi Company Limited
            </a>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <a
                  href="https://www.scalefi.co.tz/"
                  className="nav-link text-muted"
                  target="_blank"
                  style={{ paddingRight: 30 }}
                >
                  About ScaleFi
                </a>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
