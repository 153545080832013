import React from "react";
import {Modal, Button, Input} from "antd";
import moment from "moment";
import {useTheme} from "../../hooks";


const LoanGuarantorApprovalModal = ({
                                        transaction,
                                        modalVisible,
                                        handleModalClose,
                                        handleFormSubmit,
                                        isLoading,
                                        remarks,
                                        setRemarks
                                    }) => {
    const {theme: {primaryColor}} = useTheme();




    const handleRemarksChange = (e) => {
        setRemarks(e.target.value);
    };

    if (!modalVisible) return <></>;


    const modalButtons = (transaction.guarantorStatus === 'PENDING') ? [
        <Button key="reject" onClick={() => handleFormSubmit({
            status: "REJECTED",
            loanId: transaction.loanId,
            guarantorId: transaction.guarantorId
        })}>
            Reject
        </Button>,
        <Button loading={isLoading} style={{backgroundColor: primaryColor}} key="approve" type="primary"
                onClick={() => handleFormSubmit({
                    status: "APPROVED",
                    loanId: transaction.loanId,
                    guarantorId: transaction.guarantorId
                })}>
            Approve
        </Button>,
    ] : [<Button loading={isLoading} style={{backgroundColor: primaryColor}} key="close" type="primary"
                 onClick={() => handleModalClose()}>
        Close
    </Button>]


    return (
        <Modal
            title="Loan Guarantor Approval"
            visible={modalVisible}
            onCancel={handleModalClose}
            footer={modalButtons}
        >
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{textAlign: "center"}}>
                    <span>Name: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.name}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Product Name: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.productName}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Product Code: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.glNo}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Application Id: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.loanId}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Loan Period: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.lnPeriod}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Loan Amount: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.loanAmt}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Application Date: </span>
                    <span  style={{fontWeight: "bold"}}>{moment(transaction.appliDate).format("DD MMM, yyyy")}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Guarantor Status: </span>
                    <span style={{fontWeight: "bold",color: transaction.statusColor}}>{transaction.guarantorStatus}</span>
                </div>
                <div style={{textAlign: "center", marginBottom: 50}}>
                    <span>Remarks: </span>
                    <span  style={{fontWeight: "bold"}}>{transaction.currentGuarantorRemarks}</span>
                </div>
            </div>
            {transaction.guarantorStatus === 'PENDING' && (<Input.TextArea
                placeholder="Remarks"
                value={remarks}
                onChange={handleRemarksChange}
                autoSize={{minRows: 3, maxRows: 5}}
            />)}

        </Modal>
    );
};

export default LoanGuarantorApprovalModal;
