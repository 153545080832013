import React from "react";
import {Card, Col, Row, Spin, Typography} from "antd";
import {format} from "../../utils/money";

const {Title} = Typography;


const DispayExtra = ({exra}) => {
    if (!exra) return "";
    return <Col xs={8}>{exra}</Col>;
};

const DisplayContent = ({label, children}) => {
    return (
        <Col xs={16}>
            <span>{label}</span>
            {children}
        </Col>
    );

};

const DTitle = ({title}) => {
    return (
        <Title level={3}>
            {title}<small className={"bnb2"}>{""}</small>
        </Title>
    )
}

const DCard = ({label, children, extra}) => {
    return (<Card bordered={false} className="criclebox " style={{marginInline:"4px"}}>
        <div className="number">
            <Row align="middle" gutter={[24, 0]}>
                <DisplayContent label={label}>
                    {children}
                </DisplayContent>
                <DispayExtra exra={extra}/>
            </Row>
        </div>
    </Card>);
}

function DisplayCard({label, amount, extra, isLoading, isError}) {

    if (isError) {
        return (
            <DCard extra={extra} label={label}>
                <DTitle title={"Loading Failed "}/>;
            </DCard>
        )
    }

    if (isLoading) {
        return (<DCard extra={extra} label={label}>
            <DTitle title={<Spin size={"large"}/>}/>
        </DCard>)
    }

    return (
        <DCard extra={extra} label={label}>
            <DTitle title={format(amount)}/>
        </DCard>
    );
}

export default DisplayCard;
