import { Row, Col, Card } from "antd";
import { YourTransaction, AccountStatementForm } from "../components/widgets";

function AccountActivity() {
  return (
    <>
      <Row gutter={[24, 0]}>
            <Col span={24} md={8} className="mb-24">
              <Card
                bordered={false}
                bodyStyle={{ paddingTop: 0 }}
                className="header-solid h-full  ant-list-yes"
                title={<h6 className="font-semibold m-0">Account Statement</h6>}
                extra={<p className="card-header-date"></p>}
              >
                <AccountStatementForm />
              </Card>
            </Col>
            <Col span={24} md={16} className="mb-24">
              <YourTransaction />
            </Col>
      </Row>
    </>
  );
}

export default AccountActivity;
