import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Select, Form, Input, Button, Tag, Radio } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import map from "lodash/map";

import { savePublication, getPublication } from "../../api/posts";
import SelectAutocomplete from "./event-guide/SelectAutocomplete";
import { notifySuccess, notifyWarning } from "../../utils/notify";
import { ScaleInput, ScaleSelect } from "../scale-form";
import { getSearchPost, findPostIn } from "../../api/posts";
import SlideShow from "./SlideShow";
const { useForm } = Form;

const options = [
  { label: "Slide Show", value: "SLIDE" },
  { label: "News", value: "NEWS" },
];

export default function ContentCreator({
  show,
  handleShow,
  title,
  type,
  postCount = 1,
  formType,
  id,
}) {
  const queryClient = useQueryClient();
  const [ids, setIds] = useState([]);

  const [form] = useForm();

  const clearForm = () => {
    handleShow(false);
    form.setFieldsValue({ name: "", posts: "" });
  };
  const { data: posts, isLoading } = useQuery(["posts", ids], findPostIn, {
    initialData: [],
    enabled: ids.length > 0,
    cacheTime: 800000,
  });

  const savePublishmentMutation = useMutation(savePublication, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      clearForm();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
    onSettled: (s) => {
      queryClient.clear("publications");
    },
  });

  const { data: publication } = useQuery(["publication", id], getPublication, {
    enabled: id != null && formType === "EDIT",
  });

  useEffect(() => {
    if (publication) {
      form.setFieldsValue(publication);
    }
  }, [publication]);

  const onFinish = ({ name, title, top, posts }) => {
    const items = posts.map((post) => {
      return {
        postId: post.value,
      };
    });
    savePublishmentMutation.mutate({ id, name, top, title, type, items });
  };

  const handleSelectChange = (selections) => {
    const selected = selections.map((s) => s.value);
    setIds(selected);
  };

  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"60VW"}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[
          <Button
            form="slideShowForm"
            type="default"
            key="cancel"
            onClick={() => handleShow(false)}
            htmlType="button"
          >
            Cancel
          </Button>,
          <Button
            form="slideShowForm"
            type="primary"
            key="save"
            htmlType="submit"
          >
            Save
          </Button>,
        ]}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          name="slideShowForm"
        >
          <Row justify="space-between">
            <ScaleInput
              span={24}
              rules={[
                {
                  required: true,
                  message: "Name is required",
                },
              ]}
              name="name"
              label="Name"
            />
            {type === "NEWS" ? (
              <ScaleInput
                span={24}
                type="number"
                rules={[
                  {
                    required: true,
                    message: "Priority is required",
                  },
                ]}
                name="order"
                label="Priority"
                defaultValue="1"
              />
            ) : (
              ""
            )}

            <SelectAutocomplete
              span={24}
              mode="multiple"
              label={`Posts / Articles`}
              placeholder={`Search and select post`}
              name="posts"
              onChange={handleSelectChange}
              max={postCount}
              fetchOptions={(name) => {
                return getSearchPost({
                  query: name,
                });
              }}
              style={{
                width: "100%",
              }}
              rules={[
                {
                  required: true,
                  message: "Posts are required",
                },
              ]}
            />
          </Row>
          <Row gutter={[24, 0]}>
            <Col span={24} md={24} className="mb-24">
              <SlideShow posts={posts} isLoading={isLoading} />
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
