import React from "react";
import {Col, Row,} from "antd";


function NotAuthorized() {


    return (
        <>
            <div className="layout-content">
                <Row key="header" className="rowgap-vbox" gutter={[18, 0]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Row className="rowgap-vbox">

                            <h2>403 - Not Authorized to access this page</h2>

                        </Row>

                    </Col>


                </Row>
            </div>
        </>
    );
}

export default NotAuthorized;
