import React, { createContext, useState } from "react";
import { isTokenValid } from "../utils/auth-util";

export const AuthContext = createContext(isTokenValid());

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLogedIn] = useState(isTokenValid());

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: loggedIn,
        setAuthenticated: (isAuthenticated) => {
          setLogedIn(isAuthenticated);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
