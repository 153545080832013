import { notification } from "antd";

const notify = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

const notifyWarning = (title, description) => {
  notification["warning"]({
    message: title,
    description: description,
  });
};

const notifyError = (title, description) => {
  notification["error"]({
    message: title,
    description: description,
  });
};
const notifySuccess = (title, description) => {
  notification["success"]({
    message: title,
    description: description,
  });
};

export { notify, notifyError, notifySuccess, notifyWarning };
