import React, { useState } from "react";
import { Col, Row } from "antd";
import { getSlides } from "../api/slides";
import { getEligibleAmount } from "../api/accounts";
import { toFileList, toNewsList } from "../api/mappers";
import { searchPublications } from "../api/posts";
import { useQuery } from "react-query";

import {
  DisplayCard,
  SlideShow,
  AccountBalanceButton,
  ListCard,
} from "../components/widgets";

import { getFileMetaList } from "../api/files";

function Home() {
  const { data: slides, isLoading } = useQuery(
    ["slides", "HOME_PAGE"],
    getSlides
  );

  const [files, setFiles] = useState([]);
  const [news, setNews] = useState([]);

  const { isLoading: filesLoading } = useQuery(
    ["metas", "DOWNLOAD"],
    getFileMetaList,
    {
      initialData: [],
      onSuccess: (data) => setFiles(toFileList(data)),
    }
  );

  const { isLoading: newsLoading } = useQuery(
    ["news", { type: "NEWS" }],
    searchPublications,
    {
      initialData: [],
      onSuccess: (data) => setNews(toNewsList(data)),
    }
  );

  const {
    isLoading: eligibilityLoading,
    error: eligibilityError,
    data: { eligibleAmount, totalSavings },
  } = useQuery("eligibility", getEligibleAmount, {
    initialData: { totalSavings: 0, eligibleAmount: 0 },
  });

  return (
    <>
      <div className="layout-content">
        <Row key="header" className="rowgap-vbox" gutter={[18, 0]}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18}>
            <Row className="rowgap-vbox">
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <DisplayCard
                  amount={totalSavings}
                  label={"Total Savings"}
                  isLoading={eligibilityLoading}
                  isError={eligibilityError}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <DisplayCard
                  amount={eligibleAmount}
                  label={"Loan Eligibility"}
                  isLoading={eligibilityLoading}
                  extra={<AccountBalanceButton />}
                  isError={eligibilityError}
                />
              </Col>
              <Col span={24} md={24} className="mb-24">
                <SlideShow isLoading={isLoading} height="90VH" posts={slides} />
              </Col>
            </Row>
          </Col>

          <Col span={24} md={6} sm={24} className="mb-24">
            <Col span={24} md={24}>
              <ListCard
                title={"Latest News"}
                isLoading={newsLoading}
                data={news}
              />
            </Col>
            <Col span={24} md={24}>
              <ListCard
                title={"Important Downloads"}
                isLoading={filesLoading}
                data={files}
              />
            </Col>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Home;
