const format = (x) => {
  x = (Math.round(x * 100) / 100).toFixed(2);
  const type = x === 0 ? "" : x < 0 ? "CR" : "DR";
  x = Math.abs(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " " + type;
};
const formatAmount = (x) => {
  x = (Math.round(x * 100) / 100).toFixed(2);
  x = Math.abs(x);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ";
};

export { format,formatAmount };
