import React, { useState, useEffect } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Modal } from "antd";
import { useQuery } from "react-query";
import { getAccountStatement } from "../../api/accounts";
import statementDefiner from "../../services/statement-data-definer";
import styled from "styled-components";

const mq = window.matchMedia("(max-width: 500px)");
const isMobile = mq.matches;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PdfContainer = styled.div``;

export default function PdfMakeModal({
  title,
  show,
  statementRequest,
  handleShow,
}) {
  const { isLoading, isError, data } = useQuery(
    ["statement", statementRequest],
    () => getAccountStatement(statementRequest),
    {
      enabled: show,
    }
  );

  useEffect(() => {
    if (data) {
      const dd = statementDefiner(data);

      const pdfDocGenerator = pdfMake.createPdf(dd);

      if (isMobile) {
        pdfDocGenerator.download();
        return;
      }

      pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector("#iframeContainer");
        if (targetElement) {
          const iframe = document.createElement("iframe");
          iframe.style.width = "100%";
          iframe.style.height = "70VH";
          iframe.src = dataUrl;

          const exist = document.querySelector("#iframeContainer iframe");
          if (exist) {
            targetElement.removeChild(exist);
          }
          targetElement.appendChild(iframe);
        }
      });
    }
  }, [data, isMobile]);

  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"80VW"}
        style={{ width: "100%", resize: "auto", height: "90VH" }}
      >
        <PdfContainer id="iframeContainer"></PdfContainer>
      </Modal>
    </>
  );
}
