import React, {useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Card, Typography, Empty, Spin, List, Avatar, Modal, Button, Input, message} from "antd";
import {
    approveLoanGuarantee,
    getLoanGuarantees,
    getLoanStatus,
    getLoanTransactions,
    postLoanApplication
} from "../../api/accounts";
import moment from "moment/moment";
import {format, formatAmount} from "../../utils/money";
import {useTheme} from "../../hooks";
import LoanGuarantorApprovalModal from "./LoanGuarantorApprovalModal";
const { confirm, info } = Modal;


const {Text} = Typography;

const LoanGuarantees = () => {
    const {theme: {primaryColor}} = useTheme();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [remarks,setRemarks] = useState('');
    const queryClient = useQueryClient();

    const {isLoading, error, data: transactions} = useQuery(
        "loan-guarantees",
        getLoanGuarantees,
        {
            initialData: []
        }
    );
    const guaranteeMutation = useMutation(approveLoanGuarantee, {
        onSuccess: (data) => {
            if(data.detail){
                message.warn(data.detail);
            }else {
                if(data.statusCode === "00"){
                    message.success(data.message);
                    setRemarks('');
                    setModalVisible(false);

                }else {
                    message.warn(data.message);
                }

            }
            setLoading(false);
        },
        onError: (e) => {
            message.error(e.message);
            setLoading(false);
            console.log("error", e);
        },
        onSettled: (s) => {
            queryClient.resetQueries("loan-guarantees")
            setLoading(false);
        },
    });

    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    const handleFormSubmit = ({loanId,guarantorId,status}) => {
        if(status === "REJECTED" && remarks.length<10) {
            info({
                title: "Please fill remarks of at least 10 characters",
                okText:'Ok'

            });
            return;
        }


        confirm({
            title: "Are you sure you want perform this action",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk() {
                // Handle delete logic here
                const request = {
                    loanId,
                    guarantorId,
                    status,
                    remarks,
                };
                guaranteeMutation.mutate(request);
            },
            onCancel() {
                console.log("Delete canceled");


            },
        });


        // Handle form submission logic here
        console.log("Form values:", {loanId,guarantorId,status});
        // Close the modal after form submission
    };

    if (isLoading || error) {
        return <Spin/>;
    }

    return (
        <Card
            bordered={false}
            bodyStyle={{paddingTop: 0, paddingBottom: 50}}
            className="header-solid h-full  ant-list-yes"
            title={<h6 className="font-semibold m-0">My Loan Guarantees </h6>}
            extra={
                <p className="card-header-date">
                    <span></span>
                </p>
            }
        >
            <List
                className="yestday transactions-list"
                header={<h6>Loan Guarantees</h6>}
                itemLayout="horizontal"
                dataSource={transactions}
                renderItem={(item) => (
                    <List.Item onClick={() => openModal(item)} style={{ cursor: "pointer" }}>
                        <List.Item.Meta
                            avatar={
                                <Avatar size="small" style={{backgroundColor: item.statusColor}}
                                        className={"text-success"}>
                                    {""}
                                </Avatar>
                            }
                            title={`${item.name} - ( ${item.productName}) `}
                            description={(
                                <span style={{color: item.statusColor}}>
    {`${item.guarantorStatus} (${moment(item.appliDate).format(
        "DD MMM, yyyy ddd"
    )})`}
  </span>
                            )}
                        />
                        <div className="amount">
            <span className={"text-success"} style={{color: primaryColor}}>
              {formatAmount(Number(item.loanAmt))}
            </span>
                        </div>
                    </List.Item>
                )}
            />
            <LoanGuarantorApprovalModal
                transaction={selectedTransaction}
                modalVisible={modalVisible}
                handleModalClose={handleModalClose}
                handleFormSubmit={handleFormSubmit}
                isLoading={loading}
                remarks={remarks}
                setRemarks={setRemarks}
            />
        </Card>
    );
};

export default LoanGuarantees;
