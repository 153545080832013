import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Select, Form, Input, Button, Tag, Radio } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import map from "lodash/map";

import {
  getRoles,
  getSearchableCustomers,
  setInitialPassword,
  saveUser,
  getCustomer,
  getUser,
} from "../../api/accounts";
import SelectAutocomplete from "./event-guide/SelectAutocomplete";
import { notifySuccess, notifyWarning } from "../../utils/notify";
import Checkbox from "antd/lib/checkbox/Checkbox";
const { useForm } = Form;

const { Option } = Select;

const options = [
  { label: "Member", value: "Member" },
  { label: "Non Member", value: "Non Member" },
];

export default function ScaleUser({ show, handleShow, type, title, userId }) {
  const [userType, setUserType] = useState("Member");
  const [disableUserType, setDisableUserType] = useState(false);
  const [custId, setCustId] = useState(null);
  const { data: roles } = useQuery("roles", getRoles);
  const { data: initialPassword } = useQuery(
    "initialPassword",
    setInitialPassword
  );
  const queryClient = useQueryClient();
  const [resetPassword, setResetPassword] = useState(false);

  const toggleResetPassword = (e) => {
    setResetPassword(e.target.checked);
  };


  const [form] = useForm();

  const clearForm = () => {
    setCustId(null);
    form.setFieldsValue({
      // customer: "",
      userId: "",
      phoneNo: "",
      branch: "",
      custNo: "",
      email: "",
      customerPassword: "",
      role: "",
    });
    handleShow(false);
  };

  const saveUserMutation = useMutation(saveUser, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      clearForm();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
    onSettled: (s) => {
      queryClient.invalidateQueries("users");
    },
  });

  const { data: customer } = useQuery(["customer", custId], getCustomer, {
    enabled: custId != null,
  });

  const { data: user } = useQuery(["customer", userId], getUser, {
    enabled: userId != null && type === "EDIT",
  });

  useEffect(() => {
    console.log(type);
    if (type === "EDIT") {
      setDisableUserType(true);
    } else {
      setDisableUserType(false);
    }
  }, [type]);

  useEffect(() => {
    if (customer) {
      const userId = sugestUsername(customer.name);
      const phoneNo = customer.phone?.trim();
      const branch = customer.branch;
      const custNo = customer.custNo;
      form.setFieldsValue({
        userId,
        phoneNo,
        branch,
        custNo,
      });
    }
  }, [customer]);

  useEffect(() => {
    form.setFieldsValue({ userType });
  }, [userType]);

  useEffect(() => {
    if (user) {
      if (!user.custNo || user.custNo === "") {
        setUserType("Non Member");
      } else {
        setUserType("Member");
      }
      form.setFieldsValue({
        ...user,
      });
    }
  }, [user]);

  const onFinish = (values) => {
    let _user = {
      ...values,
      type,
      resetPassword,
    };
    if (type === "ADD" && userType === "Member") {
      _user.fullName = customer.name;
    }

    saveUserMutation.mutate(_user);
  };

  const userTypeChange = (e) => {
    setUserType(e.target.value);
  };

  const handleSelectChange = ([_customer]) => {
    if (_customer) {
      setCustId(_customer.key);
    }
  };
  const sugestUsername = (name) => {
    let _name = name.trim().toLowerCase().replaceAll(",", "");
    let names = _name.split(" ");
    if (names.length < 2) return _name;
    return names[0] + "." + names[names.length - 1];
  };

  const handleFullNameChange =(e)=>{
    const userId = sugestUsername(e.target.value);
    form.setFieldsValue({userId});

  }



  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"60VW"}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[
          <Button
            form="scaleUserForm"
            type="default"
            key="cancel"
            onClick={() => handleShow(false)}
            htmlType="button"
          >
            Cancel
          </Button>,
          <Button
            form="scaleUserForm"
            type="primary"
            key="save"
            htmlType="submit"
          >
            Save
          </Button>,
        ]}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          name="scaleUserForm"
        >
          <Row justify="space-between">
            <Col key="userTypeCol" span={24}>
              <Form.Item name="userType" label="User Type">
                <Radio.Group
                  options={options}
                  onChange={userTypeChange}
                  value={userType}
                  optionType="button"
                  buttonStyle="solid"
                  disabled={disableUserType}
                />
              </Form.Item>
            </Col>

            <Col key="nameCol" span={24}>
              {userType === "Member" && type === "ADD" ? (
                <SelectAutocomplete
                  mode="multiple"
                  label={`Name`}
                  placeholder={`Search and select customer`}
                  name="customer"
                  onChange={handleSelectChange}
                  max={1}
                  fetchOptions={(name) => {
                    return getSearchableCustomers({
                      nameLike: name,
                    });
                  }}
                  style={{
                    width: "100%",
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                />
              ) : (
                <Form.Item
                  name="fullName"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Full Name is required",
                    },
                  ]}
                >
                  <Input onChange={handleFullNameChange} />
                </Form.Item>
              )}
            </Col>
            {userType === "Member" ? (
              <>
                <Col key="branchCol" span={24}>
                  <Form.Item name="branch" label="Branch">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>

                <Col key="custNoCol" span={24}>
                  <Form.Item name="custNo" label="Cust No.">
                    <Input readOnly={true} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <></>
            )}
            <Col key="usernameCol" span={24}>
              <Form.Item
                name="userId"
                label="Customer Username"
                rules={[
                  {
                    required: true,
                    message: "Username is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col key="emailCol" span={24}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col key="phoneNoCol" span={24}>
              <Form.Item
                name="phoneNo"
                label="Phone No"
                rules={[
                  {
                    required: true,
                    message: "Phone No. is required",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {type === "EDIT" ? (
              <Col key="resetPasswordCol" span={24}>
                <Form.Item name="resetPassword" label="Reset Password">
                  <Checkbox
                    checked={resetPassword}
                    onChange={toggleResetPassword}
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            {(type === "ADD" && initialPassword) ||
            (type === "EDIT" && initialPassword && resetPassword) ? (
              <Col key="passwordCol" span={24}>
                <Form.Item
                  name="customerPassword"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                  ]}
                >
                  <Input placeholder="input login password" />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}

            <Col key="roleCol" span={24}>
              {/* payment modality field */}
              <Form.Item
                name="roleId"
                label="role"

                rules={[
                  {
                    required: true,
                    message: "Role is required",
                  },
                ]}
              >
                <Select placeholder="Select Role" size="large" allowClear>
                  {map(roles, (r) => (
                    <Option value={r.id} key={r.role}>
                      <Tag style={{ backgroundColor: r.labelColor }}>*</Tag>
                      {r.name}{" "}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {/* end payment modality field */}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
