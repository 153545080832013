import React from "react";
import {Button, Col, Form, Input} from "antd";

function ScaleInput({name, label, span, rules, hidden, extra, extraWidth, ...rest}) {
    if (hidden) return (
        <Form.Item noStyle name={name}>
            <Input hidden={true} {...rest} />
        </Form.Item>);
    if (extra) {
        return (
            <Col span={span}>
                <Form.Item name={name} label={label} rules={rules}>
                    <Input.Group compact>
                        <Form.Item noStyle name={name}>
                            <Input {...rest} style={{width: `calc(100% - ${extraWidth})`}}/>
                        </Form.Item>
                        {extra}
                    </Input.Group>
                </Form.Item>
            </Col>
        );
    }
    return (
        <Col span={span}>
            <Form.Item name={name} label={label} rules={rules}>
                <Input {...rest} />
            </Form.Item>
        </Col>
    );
}

export default ScaleInput;
