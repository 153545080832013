const authorizationMap = {
  ADMIN_ROLE: [
    "/",
    "/loans/application",
    "/loans/status",
    "/loans/guarantees",
    "/accounts/activities",
    "/news",
    "/news/*",
    "/cms/editor",
    "/cms/editor/*",
    "/cms/attachment",
    "/cms/content-creator",
    "/admin/user-management",
    "/admin/customization",
  ],
  ROLE_ADMIN: [
    "/",
    "/loans/application",
    "/loans/status",
    "/loans/guarantees",
    "/accounts/activities",
    "/news",
    "/news/*",
    "/cms/editor",
    "/cms/editor/*",
    "/cms/attachment",
    "/cms/content-creator",
    "/admin/user-management",
    "/admin/customization",
  ],
  ROLE_USER_ADMIN: [
    "/",
    "/loans/application",
    "/loans/status",
    "/accounts/activities",
    "/loans/guarantees",
    "/news",
    "/news/*",
    "/cms/editor",
    "/cms/editor/*",
    "/cms/attachment",
    "/cms/content-creator",
    "/admin/user-management",
    "/admin/customization",
  ],
  ROLE_MEMBER: ["/", "/accounts/activities",
    "/loans/guarantees","/news", "/news/*"],
  ROLE_LOAN_ADMIN: [
    "/",
    "/loans/application",
    "/loans/guarantees",
    "/loans/status",
    "/accounts/activities",
    "/news",
    "/news/*",
  ],
};

function matchRuleShort(str, rule) {
  var escapeRegex = (str) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
  return new RegExp(
    "^" + rule.split("*").map(escapeRegex).join(".*") + "$"
  ).test(str);
}

export const authorizer = (userData, path) => {
  if (!userData) return false;

  const { role } = userData;

  const map = authorizationMap[role];

  if (!map) return false;

  return findMatch(map, path);
};

const findMatch = (map, path) => {
  let result = false;
  map.forEach(function (rule) {
    let match = matchRuleShort(path, rule);
    if (match) result = true;
  });
  return result;
};
