import { Card, Row, Col, Form, Button, Space } from "antd";

import React, { useState, useEffect } from "react";
import { ScaleInput } from "../components/scale-form";
import ColorPickerButton from "../components/widgets/ColorPickerButton";
import { useTheme } from "../hooks";
import { useMutation } from "react-query";
import { saveThemeColors, saveUser } from "../api/accounts";
import { notifySuccess, notifyWarning } from "../utils/notify";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export default function Customization() {
  const { useForm } = Form;
  const [form] = useForm();
  const { theme, reset, applyTheme } = useTheme();

  const [themeColors, setThemeColors] = useState(theme);

  const saveThemeMutation = useMutation(saveThemeColors, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      reset();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
  });

  const onFinish = (values) => {
    saveThemeMutation.mutate(values);
  };

  const handleSideBgColorChange = (e) => {
    //  setSideBackgroundColor(e.target.value)
  };
  const handlePrimaryChange = (e) => {
    setThemeColors({ ...themeColors, primaryColor: e.target.value });
  };

  const handleSecondaryChange = (e) => {
    setThemeColors({ ...themeColors, secondaryColor: e.target.value });
  };

  const handleBackgroundChange = (e) => {
    setThemeColors({ ...themeColors, backgroundColor: e.target.value });
  };

  const handlePrimaryChangePicked = (color) => {
    const { r, g, b, a } = color.rgb;
    setThemeColors({
      ...themeColors,
      primaryColor: `rgba(${r},${g},${b},${a})`,
    });
    form.setFieldsValue({ primaryColor: `rgba(${r},${g},${b},${a})` });
  };

  const handleSecondaryChangePicked = (color) => {
    const { r, g, b, a } = color.rgb;
    setThemeColors({
      ...themeColors,
      secondaryColor: `rgba(${r},${g},${b},${a})`,
    });
    form.setFieldsValue({ secondaryColor: `rgba(${r},${g},${b},${a})` });
  };

  const handleBackgroundChangePicked = (color) => {
    const { r, g, b, a } = color.rgb;
    setThemeColors({
      ...themeColors,
      backgroundColor: `rgba(${r},${g},${b},${a})`,
    });
    form.setFieldsValue({ backgroundColor: `rgba(${r},${g},${b},${a})` });
  };

  const onReset = () => {
    reset();
  };

  const handlePreview = () => {
    applyTheme(themeColors);
  };

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              bodyStyle={{ paddingTop: 0 }}
              className="header-solid h-full  ant-list-yes"
              title={
                <h6 className="font-semibold m-0">Appearance Customization</h6>
              }
              extra={<Row className="card-header-date"></Row>}
            >
              <Form
                {...layout}
                autoComplete="off"
                onFinish={onFinish}
                form={form}
                name="form"
                initialValues={themeColors}
              >
                <Row justify="space-between">
                  <ScaleInput
                    label={"Primary Color"}
                    name={"primaryColor"}
                    onChange={handlePrimaryChange}
                    span={18}
                    addonAfter={
                      <ColorPickerButton
                        colorPicked={handlePrimaryChangePicked}
                        color={themeColors.primaryColor}
                        small={true}
                      />
                    }
                  />
                  <ScaleInput
                    label={"Secondary Color"}
                    name={"secondaryColor"}
                    onChange={handleSecondaryChange}
                    span={18}
                    addonAfter={
                      <ColorPickerButton
                        colorPicked={handleSecondaryChangePicked}
                        color={themeColors.secondaryColor}
                        small={true}
                      />
                    }
                  />
                  <ScaleInput
                    label={"Background Color"}
                    name={"backgroundColor"}
                    onChange={handleBackgroundChange}
                    span={18}
                    addonAfter={
                      <ColorPickerButton
                        colorPicked={handleBackgroundChangePicked}
                        color={themeColors.backgroundColor}
                        small={true}
                      />
                    }
                  />
                </Row>

                <Form.Item {...tailLayout}>
                  <Button htmlType="button" onClick={onReset}>
                    Reset
                  </Button>
                  &nbsp; &nbsp; &nbsp;
                  <Button htmlType="button" onClick={handlePreview}>
                    Preview
                  </Button>
                  &nbsp; &nbsp; &nbsp;
                  <Button className={"mr-4"} type="primary" htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
