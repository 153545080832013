import React, {useState} from "react";
import {Card, Col, Row, Tag} from "antd";


function ComingSoon() {


    return (
        <>
            <div className="layout-content">
                <Row key="header" className="rowgap-vbox" gutter={[18, 0]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Row className="rowgap-vbox">
                            <Card bordered={false} className="criclebox " style={{marginInline: "4px"}}>
                                <h2>Coming Soon</h2>
                            </Card>

                        </Row>

                    </Col>


                </Row>
            </div>
        </>
    );
}

export default ComingSoon;
