import { Card, Table, Space, Row, Col, Input, Button } from "antd";
import { searchPublications, savePublication } from "../api/posts";
import { useMutation, useQuery, useQueryClient } from "react-query";

import debounce from "lodash/debounce";

import React, { useState } from "react";
import { ContentCreator, SetSlideShow } from "../components/widgets";
import { notifySuccess, notifyWarning } from "../utils/notify";

const { Search } = Input;

export default function ContentPublisher() {
  const [searchKey, setSearchKey] = useState("");
  const [showCreator, setShowCreator] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [postCount, setPostCount] = useState(1);
  const [typeFilter, setTypeFilter] = useState(null);
  const [id, setId] = useState(null);
  const [query, setQuery] = useState(null);
  const [showSlideConfig, setSlideShowConfig] = useState(false);

  const [userId, setUserId] = useState(null);
  const queryClient = useQueryClient();

  const {  data } = useQuery(
    ["publications", { type: typeFilter, query }],
    searchPublications,
    {
      initialData: [],
    }
  );
  const clearForm = () => {};

  const savePublishmentMutation = useMutation(savePublication, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      clearForm();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
    onSettled: (s) => {
      queryClient.clear("publications");
    },
  });
  const onSearch = (value) => {
    setSearchKey(value);
  };

  const handleShowSlideConfig = (value) => {
    setSlideShowConfig(value);
  };

  const onSearchChange = (event) => {
    setSearchKey(event.target.value);
  };

  const handleShowCreator = (value) => {
    setShowCreator(value);
  };

  const handleSlideShowCreator = () => {
    setType("SLIDE");
    setTitle("Create Slide Show");
    setFormType("ADD");
    setPostCount(10);
    handleShowCreator(true);
  };

  const handleNewsCreator = () => {
    setType("NEWS");
    setTitle("Create News");
    setFormType("ADD");
    setPostCount(1);
    handleShowCreator(true);
  };

  const handleEdit = (record) => {
    setId(record.id);
    setTitle("Edit " + record.type);
    setFormType("EDIT");
    setType(record.type);
    if (record.type === "NEWS") {
      setPostCount(1);
    } else {
      setPostCount(10);
    }
    handleShowCreator(true);
  };



  const togglePublish = ({ id, name, type, top, publish }) => {
    savePublishmentMutation.mutate({ id, name, type, top, publish: !publish });
  };

  const publishButton = (record) => {
    console.log(record);
    let { publish, type } = record;
    if (type !== "NEWS") return <Button disabled>N/A</Button>;
    let text = publish ? "Un Publish" : "Publish";
    return <Button onClick={() => togglePublish(record)}>{text}</Button>;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Published",
      key: "published",
      dataIndex: "published",
      render: (published, record) => publishButton(record),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button onClick={() => handleEdit(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              bodyStyle={{ paddingTop: 0 }}
              className="header-solid h-full  ant-list-yes"
              title={<h6 className="font-semibold m-0">Content Publisher</h6>}
              extra={
                <Row className="card-header-date">
                  <Search
                    placeholder="Search by Name | Username"
                    onSearch={onSearch}
                    onChange={debounce(onSearchChange, 1600)}
                    enterButton
                  />
                </Row>
              }
            >
              <Row>
                <Col md={1} xl={1}></Col>
                <Col md={4} xl={4}>
                  <Button onClick={handleNewsCreator}>Create News</Button>
                </Col>

                <Col md={4} xl={4}>
                  <Button onClick={handleSlideShowCreator}>
                    Create Slide Show
                  </Button>
                </Col>

                <Col md={4} xl={4}>
                  <Button onClick={() => handleShowSlideConfig(true)}>
                    Configure Slide Show
                  </Button>
                </Col>
              </Row>
              <ContentCreator
                show={showCreator}
                postCount={postCount}
                handleShow={handleShowCreator}
                title={title}
                type={type}
                userId={userId}
                formType={formType}
                id={id}
              />
              <SetSlideShow
                show={showSlideConfig}
                handleShow={handleShowSlideConfig}
                title="Slide Show Config"
              />
              <Table columns={columns} dataSource={data} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
