import { Spin } from "antd";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";

const RichEditor = styled(ReactQuill)`
  .ql-editor {
    background-color: ${(props) => props.backgroundColor};
    background-size: ${(props) => props.backgroundSize};
    background-image: ${(props) => props.backgroundImage};
    background-repeat: ${(props) => props.backgroundRepeat};
    background-position: ${(props) => props.backgroundPosition};
    height: "100%";
  }
  .ql-tooltip {
    display: none;
  }
`;

export default function RichTextViewer({
  value,
  backgroundColor,
  backgroundSize,
  backgroundImage,
  backgroundRepeat,
  backgroundPosition,
  isLoading,
}) {
  if (isLoading) return <Spin size="large" />;
  return (
    <>
      <RichEditor
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        backgroundSize={backgroundSize}
        backgroundRepeat={backgroundRepeat}
        backgroundPosition={backgroundPosition}
        readOnly={true}
        theme="bubble"
        value={value}
        onChange={(event) => {}}
      />
    </>
  );
}
