/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import { Layout, Button, Row, Col, Typography, Form, Input } from "antd";
import { useHistory } from "react-router-dom";

import { AUTH_URL } from "../configs/constants";
import { post } from "../utils/client";
import { getBaseUrl, isTokenValid } from "../utils/auth-util";
import { notifyWarning } from "../utils/notify";
import { useAuth, useUserData } from "../hooks";
import { useQuery } from "react-query";
import { getSlides } from "../api/slides";
import { SlideShow } from "../components/widgets";
import {getCompanyBrand} from "../api/accounts";

const { Title } = Typography;
const { Header, Footer, Content } = Layout;



const getFooterStyle = (isMobile)=>{
  if(isMobile){
    return {};
  }else{
    return {
      position: "absolute",
      bottom: 0,
      width: "100%",
      height: "60px",
      background: "rgb(242, 242, 242)",
    };
  }

}

export default function SignIn() {
  const mq = window.matchMedia("(max-width: 500px)");
  const isMobile = mq.matches;
  const { setAuthenticated } = useAuth();
  const { setUserData } = useUserData();

  const history = useHistory();



  const { data: slides, isLoading } = useQuery(
    ["slides", "LOGIN_PAGE"],
    getSlides
  );

  const {data:brand} = useQuery("company",getCompanyBrand,{
    initialData: {
      "branch": "SCL",
      "branchName": "HEAD OFFICE                                                                                                                                                                                                                                                   ",
      "companyName": "ScaleFi Company Limited"
    }
  })

  var baseUrl = getBaseUrl();

  const onFinish = (values) => {
    post(`${baseUrl}/auth/login`, values).then((res) => {
      if (res.statusCode === "00") {
        console.log(res);
        const userData = {
          username: values.username,
          fullName: res.data.name,
          token: res.data.token,
          custNo: res.data.custNo,
          branch: res.data.branch,
          role: res.data.role,
          organizationName: res.data.companyName,
          changePassword: res.data.changePassword,
          menus: res.data.menus,
        };
        setUserData(userData);
        setAuthenticated(isTokenValid());

        history.replace("/");
      } else {
        notifyWarning("Login Failed", res.statusDesc);
      }
    });
  };
  const currentYear = new Date().getFullYear();



  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Layout className="layout-default layout-signin">
        <Header>
          <div className="header-col header-brand">
            <h5>{brand.companyName}</h5>
          </div>
        </Header>
        <Content className="signin">
          <Row gutter={[24, 0]} justify="space-around">
            <Col
              className="sign-img"
              style={{ padding: 12 }}
              xs={{ span: 24 }}
              lg={{ span: 16 }}
              md={{ span: 16 }}
            >
              <SlideShow isLoading={isLoading} height="30VH" posts={slides} />
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 1 }}
              md={{ span: 8 }}
            >
              <Title className="mb-15">Sign In</Title>

              <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <Form.Item
                  className="username"
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input placeholder="Username" />
                </Form.Item>

                <Form.Item
                  className="username"
                  label="Password"
                  name="password"
                  type="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input placeholder="Password" type="password" />
                </Form.Item>

                {/* <Switch defaultChecked onChange={onChange} />
                    Remember me */}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    SIGN IN
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Content>
        <Footer style={getFooterStyle(isMobile)}>
          <p className="copyright">
            &copy; {currentYear} ScaleFi Company Ltd.
          </p>
        </Footer>
      </Layout>
    </>
  );
}
