import React from "react";
import {Modal, Button, Input} from "antd";
import moment from "moment";
import {useTheme} from "../../hooks";


const LoanStatusModal = ({
                             transaction,
                             modalVisible,
                             handleModalClose,
                         }) => {
    const {theme: {primaryColor}} = useTheme();


    if (!modalVisible) return <></>;


    const modalButtons = [<Button style={{backgroundColor: primaryColor}} key="close" type="primary"
                                  onClick={() => handleModalClose()}>
        Close
    </Button>]


    return (
        <Modal
            title="Loan Guarantor Approval"
            visible={modalVisible}
            onCancel={handleModalClose}
            footer={modalButtons}
        >
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{textAlign: "center"}}>
                    <span>Name: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.name}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Product Name: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.productName}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Product Code: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.glNo}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Application Id: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.loanId}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Loan Period: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.lnPeriod}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Loan Amount: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.loanAmt}</span>
                </div>
                <div style={{textAlign: "center"}}>
                    <span>Application Date: </span>
                    <span style={{fontWeight: "bold"}}>{moment(transaction.appliDate).format("DD MMM, yyyy")}</span>
                </div>

                {transaction.guarantorApprove && (<>
                        <div style={{textAlign: "center"}}>
                            <span>Guarantor 1 Status: </span>
                            <span style={{fontWeight: "bold"}}>{transaction.guarantor1Status}</span>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <span>Guarantor 1 Remarks: </span>
                            <span style={{fontWeight: "bold"}}>{transaction.guar1Remarks}</span>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <span>Guarantor 2 Status: </span>
                            <span style={{fontWeight: "bold"}}>{transaction.guarantor2Status}</span>
                        </div>
                        <div style={{textAlign: "center"}}>
                            <span>Guarantor 2 Remarks: </span>
                            <span style={{fontWeight: "bold"}}>{transaction.guar2Remarks}</span>
                        </div>
                    </>)

                }
                <div style={{textAlign: "center"}}>
                    <span>Loan Status: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.loanStatus}</span>
                </div>

                <div style={{textAlign: "center"}}>
                    <span>Loan Status Description: </span>
                    <span style={{fontWeight: "bold"}}>{transaction.loanStatusDescription}</span>
                </div>

            </div>

        </Modal>
    )
        ;
};

export default LoanStatusModal;
