import { get, post } from "./client";
import { getUserData } from "../utils/auth-util";

export const getEligibleAmount = async () => {
  const { branch, custNo } = getUserData();
  return get(`/accounts/${branch}/${custNo}/loan-eligibility`).then((res) =>
    res.json()
  );
};

export const getAccountBalances = async () => {
  const { branch, custNo } = getUserData();
  return get(`/accounts/${branch}/${custNo}/balance`).then((res) => res.json());
};

export const getAssetTransactions = async () => {
  const { branch, custNo } = getUserData();
  return get(`/transactions/${branch}/${custNo}/assets`).then((res) =>
    res.json()
  );
};

export const getLoanTransactions = async () => {
  const { branch, custNo } = getUserData();
  return get(`/loans/${branch}/${custNo}/repayments`)
      .then((res) => res.json());
};

export const getLoanStatus = async (request) => {
  const { branch, custNo } = getUserData();
  return get(`/loans/${branch}/${custNo}/loan-applications`, request)
      .then((res) =>  res.json());
};

export const getLoanGuarantees = async (request) => {
  const { branch, custNo } = getUserData();
  return get(`/loans/${branch}/${custNo}/guaranteed-loans`, request)
      .then((res) =>  res.json());
};

export const getAccounts = async () => {
  const { branch, custNo } = getUserData();
  return get(`/accounts/${branch}/${custNo}`).then((res) => res.json());
};

export const getLoanProducts = async () => {
  const { branch, custNo } = getUserData();
  return get(`/accounts/${branch}/${custNo}?product=L`).then((res) =>
    res.json()
  );
};
export const searchApprovers = async (inputValue) => {
  const { branch } = getUserData();
  return get(`/customers/${branch}/search-customer?query=${inputValue}`).then((res) =>
    res.json()
  );
};

export const getAccountStatement = async ({ product, startDate, endDate }) => {
  console.log("getAccountStatement", { product, startDate, endDate });
  const { branch, custNo } = getUserData();
  return get(
    `/accounts/${branch}/${custNo}/statement?fromDate=${startDate}&toDate=${endDate}&glNo=${product}`
  ).then((res) => res.json());
};

export const postLoanApplication = async (request) => {
  const { branch, custNo } = getUserData();
  return post(`/loans/${branch}/${custNo}/apply`, request).then((res) => {
    return res.json();
  });
};

export const approveLoanGuarantee = async (request) => {
  const { branch, custNo } = getUserData();
  return post(`/loans/${branch}/${custNo}/loan-guaranteed`, request).then((res) => {
    return res.json();
  });
};

export const getEventGuide = async ({ domain, eventId }) => {
  return get(`/loans/${domain}/${eventId}/guide`).then((res) => res.json());
};

export const getBrand = async () => {
  const { branch, custNo } = getUserData();
  const res = await get(`/companies/brand/${branch}`);
  return await res.json();
};

export const getCompanyBrand = async () => {
  return get(`/companies/brand`).then((res) => res.json());
};

export const getUsers = async ({ queryKey }) => {
  const [key, searchKey] = queryKey;
  const { branch } = getUserData();
  const res = await get(`/users/${branch}?searchKey=${searchKey}`);
  return await res.json();
};

export const getUser = async ({ queryKey }) => {
  const [key, userId] = queryKey;
  const { branch } = getUserData();
  const res = await get(`/users/${branch}?userId=${userId}`);
  return await res.json();
};

export const getRoles = async () => {
  const res = await get(`/roles`);
  return await res.json();
};

export const setInitialPassword = async () => {
  const { branch, custNo } = getUserData();

  const res = await get(`/users/${branch}/initial-password`);
  return await res.json();
};

export const getCustomer = async ({ queryKey }) => {
  const [key, custId] = queryKey;

  const [branch, custNo] = custId.split(".");
  const res = await get(`/customers/${branch}/${custNo}`);
  return await res.json();
};

export const getSearchableCustomers = async ({ nameLike }) => {
  const { branch, custNo } = getUserData();

  const response = await get(
    `/customers/${branch}/search-customer?query=${nameLike}`
  );
  const customers = await response.json();
  if (customers) {
    return customers.map((customer) => ({
      label: `${customer.name}`,
      value: customer.branch + "." + customer.custNo,
    }));
  }
  return [];
};

export const saveUser = async (request) => {
  const { branch } = getUserData();
  request.branch =branch;

  const res = await post(`/users/${branch}`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};

export const saveThemeColors = async (request) => {
  const { branch } = getUserData();
  request.branch =branch;

  const res = await post(`/companies/${branch}/update`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};
