import React from "react";
import { Form, Row, Col, Input, Card, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { notifySuccess, notifyWarning } from "../utils/notify";

import debounce from "lodash/debounce";
import { ScaleFormItem, ScaleInput } from "../components/scale-form";
import {getBaseUrl, getJwtToken} from "../utils/auth-util";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { updateFileUpload, getSearchableFileCategory } from "../api/files";
import ScaleAutocomplete from "../components/scale-form/ScaleAutocomplete";
import ScaleAutoCompleteInput from "../components/scale-form/ScaleAutoCompleteInput";

const { Search } = Input;
const { useForm } = Form;

function AttachmentManager() {
  const [form] = useForm();
  const queryClient = useQueryClient();

  const onSearchChange = (value) => {};
  const onSearch = (value) => {};

  const clearForm = () => {
    form.setFieldsValue({
      id: "",
      originalFileName: "",
      fileName: "",
      type: "",
      description: "",
    });
  };

  const updateFileUploadMutation = useMutation(updateFileUpload, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      clearForm();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
    onSettled: (s) => {
      queryClient.clear("fileList");
    },
  });

  const onFinish = (values) => {
    console.log(values);
    updateFileUploadMutation.mutate(values);
  };

  const BASE_URL = getBaseUrl();
  const props = {
    name: "file",
    action: `${BASE_URL}/files/upload`,
    headers:{ 'Authorization': `Bearer ${getJwtToken()}` },
    onChange(info) {
      const {
        file: { response, type },
      } = info;
      if (response) {
        form.setFieldsValue(response);
      }
      if (type) {
        form.setFieldsValue({ type });
      }
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs="24" xl={24}>
          <Card
            bordered={false}
            bodyStyle={{ paddingTop: 0 }}
            className="header-solid h-full  ant-list-yes"
            title={<h6 className="font-semibold m-0">Attachment Manager</h6>}
            extra={
              null
              //   <Row className="card-header-date">
              //     <Search
              //       placeholder="Search by Name | Username"
              //       onSearch={onSearch}
              //       onChange={debounce(onSearchChange, 1600)}
              //       enterButton
              //     />
              //   </Row>
            }
          >
            <Row>
              <Col
                span={24}
                md={24}
                style={{ paddingTop: "40px", marginBottom: "200px" }}
              >
                <Form
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }}
                  autoComplete="off"
                  onFinish={onFinish}
                  form={form}
                  name="attachmentForm"
                >
                  <Row justify="space-between">
                    <Upload showUploadList={false} {...props}>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </Row>
                  <br />
                  <ScaleInput
                    name="fileName"
                    label="Name"
                    span={12}
                    rules={[
                      {
                        required: true,
                        message: "Name is required",
                      },
                    ]}
                  />

                  <ScaleAutoCompleteInput
                    name="category"
                    label="Category"
                    span={12}
                    allowClear={true}
                    rules={[
                      {
                        required: true,
                        message: "Category is required",
                      },
                    ]}
                    fetchOptions={(category) => {
                      return getSearchableFileCategory({
                        query: category,
                        categoryType: "FILE",
                      });
                    }}
                  />

                  <ScaleInput
                    name="description"
                    label="Description"
                    span={12}
                  />
                  <ScaleInput
                    readOnly={true}
                    name="originalFileName"
                    label="Original File Name"
                    span={12}
                  />
                  <ScaleInput
                    readOnly={true}
                    name="id"
                    label="File ID"
                    span={12}
                    rules={[
                      {
                        required: true,
                        message: "File Id is required",
                      },
                    ]}
                  />
                  <ScaleInput
                    readOnly={true}
                    name="type"
                    label="Type"
                    span={12}
                  />

                  <Button
                    form="attachmentForm"
                    type="primary"
                    key="save"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default AttachmentManager;
