import React from "react";


import {Card, List, Button, Spin} from "antd";
import {StarOutlined} from "@ant-design/icons";


export default function ListCard({title, data, isLoading}) {

    // const loadMore = (<div
    //     style={{
    //         textAlign: 'center',
    //         marginTop: 2,
    //         height: 28,
    //         lineHeight: '32px',
    //         backgroundColor:"white"
    //     }}
    // >
    //     <span>loading more</span>
    // </div>)

    if (isLoading) {
        return (
            <Card
                bordered={false}
                className="header-solid h-full ant-invoice-card"
                title={[
                    <h6 key="title" className="font-semibold m-0">
                        {title}
                    </h6>,
                ]}
                extra={[<StarOutlined key="star"/>]}
            > <Spin size="large"/>
            </Card>);
    }

    return (
        <Card

            bordered={false}
            className="header-solid h-full ant-invoice-card mb-4"
            title={[
                <h6 key="title" className="font-semibold m-0">
                    {title}
                </h6>,
            ]}
            extra={[<StarOutlined key="star"/>]}
        >
            <List
                itemLayout="horizontal"
                className="invoice-list"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        actions={item.actions}
                    >
                        <List.Item.Meta title={item.name} description={item.description}/>
                    </List.Item>
                )}
            />
        </Card>
    );
}
