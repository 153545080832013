import { get, post } from "./client";

export const savePost = async (request) => {
  const res = await post(`/posts`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};

export const saveSlideConfig = async (request) => {
  const res = await post(`/publications/slide-config`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};

export const savePublication = async (request) => {
  const res = await post(`/publications`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};

export const getSearchPost = async ({ query, category }) => {
  const categoryFilter = category ? `&category=${category}` : "";
  const response = await get(`/posts/search?query=${query + categoryFilter}`);
  const list = await response.json();
  if (list) {
    return list.map((item) => ({
      value: item.id,
      label: `${item.category} : ${item.title}`,
    }));
  }
  return [];
};

export const searchPost = async ({queryKey}) => {
  console.log(queryKey);
  const [key,{ query, category }] = queryKey;
  const categoryFilter = category ? `&category=${category}` : "";
  console.log(categoryFilter)
  const response = await get(`/posts/search?query=${query + categoryFilter}`);
  return  await response.json();

};




export const getPost = async ({ queryKey }) => {
  const [key, postId] = queryKey;

  const res = await get(`/posts/${postId}`);
  return await res.json();
};

export const getPostsByPublicationId= async ({ queryKey }) => {
  const [key, publicationId] = queryKey;

  const res = await get(`/publications/news/${publicationId}`);
  return await res.json();
};

export const findPostIn = async ({ queryKey }) => {
  const [key, ids] = queryKey;

  const res = await get(`/posts/in?ids=${ids.join(",")}`);
  return await res.json();
};

export const searchPublications = async ({ queryKey }) => {
  const [key, { type, query }] = queryKey;
  let filter = false;
  let filterString = "";
  if (type) {
    if (!filter) {
      filter = true;
      filterString += "?";
    }
    filterString += `type=${type}`;
  }
  if (query) {
    if (!filter) {
      filter = true;
      filterString += "?";
    } else {
      filterString += "&";
    }
    filterString += `query=${query}`;
  }

  const res = await get(`/publications/search${filterString}`);

  return await res.json();
};

export const getPublication = async ({ queryKey }) => {
  const [key, id,type] = queryKey;

  const res = await get(`/publications/${id}`);
  return await res.json();
};
