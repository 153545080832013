import {
  getJwtToken,
  getBaseUrl,
  getWorkflowBaseUrl,
} from "../utils/auth-util";

const CONTENT_TYPE = "application/json";
let BASE_URL = getBaseUrl();
let WORKFLOW_BASE_URL = getWorkflowBaseUrl();

export const getHeaders = () => {
  return {
    Accept: CONTENT_TYPE,
    "Content-Type": CONTENT_TYPE,
    Authorization: getJwtToken() ? `Bearer ${getJwtToken()}` : "",
  };
};

export const get = async (path, data = {}, headers) => {
  return fetch(BASE_URL + path, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: headers || getHeaders(),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
};

export const getWorkflow = async (path, data = {}, headers) => {
  return fetch(WORKFLOW_BASE_URL + path, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
    headers: headers || getHeaders(),
    redirect: "follow",
    referrerPolicy: "no-referrer",
  });
};

export const post = async (path, data = {}, headers) => {
  return fetch(BASE_URL + path, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headers || getHeaders(),
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
};
