import React from "react";
import { Col, Form, Select } from "antd";
import map from "lodash/map";

const { Option } = Select;

function ScaleSelect({
  data,
  name,
  label,
  span,
  rules,
  size = "large",
  ...rest
}) {
  return (
    <Col span={span}>
      <Form.Item name={name} label={label} rules={rules}>
        <Select size={size} {...rest}>
          {map(data, ({ value, text }) => (
            <Option value={value} key={value}>
              {text}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
}

export default ScaleSelect;
