import React from "react";
import map from "lodash/map";
import { Carousel, Spin } from "antd";
import RichTextViewer from "./RichTextViewer";

function SlideShow({
  posts,
  isLoading,
  height = "50vh",
  color = "#fff",
  lineHeight = "160px",
  textAlign = "center",
  background = "#364d79",
}) {
  const contentStyle = { height, color, lineHeight, textAlign, background };
  if (isLoading)
    return (
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>
            <Spin />
          </h3>
        </div>
      </Carousel>
    );

  if (!posts || posts.length == 0) {
    return (
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>No Content Yet</h3>
        </div>
      </Carousel>
    );
  }

  return (
    <Carousel autoplay>
      {map(posts, (post) => (
        <RichTextViewer key={post.id} isLoading={isLoading} {...post} />
      ))}
    </Carousel>
  );
}

export default SlideShow;
