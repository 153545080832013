import React, { useState } from "react";
import moment from "moment";

import { Card, List, Avatar, Skeleton } from "antd";
import { useUserData } from "../../hooks";
import { getAssetTransactions, getLoanTransactions } from "../../api/accounts";
import { format } from "../../utils/money";
import { useQuery } from "react-query";

function LoanTransactions() {
  const { isLoading, error, data, isFetching } = useQuery(
    "loan-transactions",
    getLoanTransactions
  );

  if (isLoading) return <Skeleton active />;

  if (error) return "An error has occurred: " + error.message;
  return (
    <List
      className="yestday transactions-list"
      header={<h6>Recent Loan Transactions</h6>}
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar size="small" className={"text-success"}>
                {"-"}
              </Avatar>
            }
            title={item.productName}
            description={`${item.narration} (${moment(item.trxDate).format(
              "DD MMM, yyyy ddd"
            )})`}
          />
          <div className="amount">
            <span className={"text-success"}>
              {format(Number(item.principal) + Number(item.interest))}
            </span>
          </div>
        </List.Item>
      )}
    />
  );
}

function AssetTransactions() {
  const { isLoading, error, data, isFetching } = useQuery(
    "asset-transactions",
    getAssetTransactions
  );

  if (isLoading) return <Skeleton active />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <List
      header={<h6>Recent Savings And Shares Transactions</h6>}
      className="transactions-list ant-newest"
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar
                size="small"
                className={item.amount > 0 ? "text-light-danger" : "text-fill"}
              >
                {item.amount > 0 ? "+" : "-"}
              </Avatar>
            }
            title={item.productName}
            key={item.trxNo}
            description={`${item.narration} (${moment(item.trxDate).format(
              "DD MMM, yyyy ddd"
            )} )`}
          />
          <div className="amount">
            <span className={item.amount > 0 ? "text-danger" : "text-success"}>
              {format(item.amount)}
            </span>
          </div>
        </List.Item>
      )}
    />
  );
}

export default function YourTransaction() {
  return (
    <>
      <Card
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
        className="header-solid h-full  ant-list-yes"
        title={<h6 className="font-semibold m-0">Your Transactions</h6>}
        extra={
          <p className="card-header-date">
            <span></span>
          </p>
        }
      >
        <AssetTransactions />
        <LoanTransactions />
      </Card>
    </>
  );
}
