import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { isTokenValid } from "../utils/auth-util";
import { authorizer } from "../utils/authorizer";
import PropTypes from "prop-types";

import { useAuth, useUserData } from "../hooks";
import NotAuthorized from "../pages/NotAuthorized";

/**
 * @function
 * @name SecureRoute
 * @description Route which check authentication status and route to appropriate
 *  component
 * @param {object} properties props object
 * @returns {object} React Element
 * @version 0.1.0
 * @since 0.1.0
 */

const ResponseComponent = ({
  properties,
  props,
  isAuthenticated,
  isAuthorized,
}) => {
  const { component: Component, ...rest } = properties;

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: "/sign-in", state: { from: props.location } }} // eslint-disable-line
      />
    );
  }

  if (!isAuthorized) {
    return <NotAuthorized />;
  }

  return <Component {...props} />;
};
const SecureRoute = (properties) => {
  var { pathname } = useLocation();

  const { component: Component, ...rest } = properties;
  const { setAuthenticated } = useAuth();
  const { userData } = useUserData();

  var isAuthorized = authorizer(userData, pathname);

  const isAuthenticated = isTokenValid();

  setAuthenticated(isAuthenticated);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) => (
        <ResponseComponent
          props={props}
          properties={properties}
          isAuthenticated={isAuthenticated}
          isAuthorized={isAuthorized}
        />
      )}
    />
  );
};

/* props validation */
SecureRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
};

export default SecureRoute;
