import { useContext } from "react";
import { UserContext, AuthContext,ThemeContext } from "../providers";

export const useUserData = () => {
  const { userData, setUserData } = useContext(UserContext);

  return { userData, setUserData };
};

export const useAuth = () => {
  const { isAuthenticated, setAuthenticated } = useContext(AuthContext);

  return { isAuthenticated, setAuthenticated };
};


export const useTheme = () =>{
  const { reset,theme ,applyTheme} = useContext(ThemeContext);

  return { reset,theme,applyTheme };
}
