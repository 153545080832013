import React, {useState} from 'react';

import {useParams} from "react-router-dom";
import {Card, Col, Row} from "antd";
import { ListCard, RichTextViewer} from "../components/widgets";
import {useQuery} from "react-query";
import {searchPublications, getPostsByPublicationId} from "../api/posts";
import {toNewsList} from "../api/mappers";
import map from "lodash/map";


const News = () => {
    const {id} = useParams();

    const [news, setNews] = useState([]);

    const {isLoading: newsLoading} = useQuery(["news", {type: "NEWS"}], searchPublications, {
        initialData: [],
        onSuccess: (data) => setNews(toNewsList(data))
    });


    const {isLoading, data: posts} = useQuery(["publication", id], getPostsByPublicationId, {
        initialData: [],
        enabled: (id !== null)
    });


    return (
        <>
            <div className="layout-content">
                <Row key="header" className="rowgap-vbox" gutter={[18, 0]}>
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                        <Row className="rowgap-vbox">


                            <Col span={24} md={24} className="mb-24">
                                <Card
                                    bordered={false}
                                    bodyStyle={{paddingTop: 0}}
                                    className="header-solid h-full  ant-list-yes"
                                    extra={
                                        <Row className="card-header-date">
                                            {/* <Search
                    placeholder="Search by Name | Username"
                    onSearch={onSearch}
                    onChange={debounce(onSearchChange, 1600)}
                    enterButton
                  /> */}
                                        </Row>
                                    }
                                >
                                    {map(posts, (post) => (
                                        <Col span={24} key={post.id}>
                                            <h2 style={{fontWeight:'bold'}}>{post.title}</h2>
                                            <RichTextViewer isLoading={isLoading} {...post} />
                                        </Col>
                                    ))}
                                </Card>
                            </Col>

                        </Row>

                    </Col>

                    <Col span={6} md={6} className="mb-24">
                        <Col span={24} md={24}>
                            <ListCard title={"Latest News"} isLoading={newsLoading} data={news}/>
                        </Col>

                    </Col>

                </Row>
            </div>
        </>
    );

};

export default News;
