import { Card, Table, Space, Row, Col, Input, Button } from "antd";
import { searchPublications, savePublication ,searchPost} from "../api/posts";
import { useMutation, useQuery, useQueryClient } from "react-query";

import debounce from "lodash/debounce";

import React, { useState } from "react";
import {ContentCreator, ContentEditor, SetSlideShow} from "../components/widgets";
import { notifySuccess, notifyWarning } from "../utils/notify";

const { Search } = Input;

export default function ContentManager() {
  const [searchKey, setSearchKey] = useState("");
  const [showCreator, setShowCreator] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [formType, setFormType] = useState("ADD");
  const [typeFilter, setTypeFilter] = useState(null);
  const [id, setId] = useState(null);
  const [query, setQuery] = useState("");

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(
      ["posts", { category: typeFilter, query:searchKey }],
      searchPost,
      {
        initialData: [],
      }
  );



  const onSearch = (value) => {
    setSearchKey(value);
  };



  const onSearchChange = (event) => {
    setSearchKey(event.target.value);
  };

  const handleShowCreator = (value) => {
    setShowCreator(value);
  };


  const handleNew = () => {
    setType("NEWS");
    setTitle("New");
    setFormType("ADD");

    handleShowCreator(true);
  };

  const handleEdit = (record) => {
    setId(record.id);
    setTitle("Edit ");
    setFormType("EDIT");
    handleShowCreator(true);
  };




  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Date Created",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Date Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => handleEdit(record)}>Edit</Button>
          </Space>
      ),
    },
  ];

  return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                  bordered={false}
                  bodyStyle={{ paddingTop: 0 }}
                  className="header-solid h-full  ant-list-yes"
                  title={<h6 className="font-semibold m-0">Content Manager</h6>}
                  extra={
                    <Row className="card-header-date">
                      <Search
                          placeholder="Search by Name"
                          onSearch={onSearch}
                          onChange={debounce(onSearchChange, 800)}
                          enterButton
                      />
                    </Row>
                  }
              >
                <Row>
                  <Col md={1} xl={1}></Col>
                  <Col md={4} xl={4}>
                    <Button onClick={handleNew}>New</Button>
                  </Col>

                </Row>
                <ContentEditor
                    show={showCreator}
                    handleShow={handleShowCreator}
                    title={title}
                    type={type}
                    formType={formType}
                    id={id}
                />
                <Table loading={isLoading} columns={columns} dataSource={data} />
              </Card>
            </Col>
          </Row>
        </div>
      </>
  );
}
