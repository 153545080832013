const get = async (
  url,
  data = {},
  headers = {
    "Content-Type": "application/json",
  }
) => {
  try {
    const response = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers,
      redirect: "follow",
      referrerPolicy: "no-referrer",
    });
    if (!response) return null;

    return response.json();
  } catch (e) {
    return null;
  }
};

const post = async (
  url,
  data = {},
  headers = {
    "Content-Type": "application/json",
  }
) => {
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

export { get, post };
