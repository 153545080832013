import { getItem } from "./storage";
import jwtDecode from "jwt-decode";
import lodashGet from "lodash/get";


const isTokenValid = () => {
  const jwtToken = getJwtToken(); // ensure token is set

  try {
    const decodedToken = jwtDecode(jwtToken);

    if (decodedToken.exp && decodedToken.exp > Math.round(Date.now() / 1000)) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};
const getJwtToken = () => {
  const userData = getItem("userData");
  if (!userData) return null;

  return userData.token;
};
const getUserData = () => {
  return getItem("userData");
};

const getBaseUrl = () => {
  // const API_URL = lodashGet(process.env, "API_BASE_URL", undefined);
  // const REACT_APP_API_BASE_URL = lodashGet(
  //   process.env,
  //   "REACT_APP_API_BASE_URL"
  // );
  // return API_URL || REACT_APP_API_BASE_URL;
  return document.querySelector('meta[name="base-url"]').content;
};


const getWorkflowBaseUrl = () => {
  console.log(lodashGet);
  const API_URL = lodashGet(process.env, "API_WORKFLOW_BASE_URL", undefined);
  const REACT_APP_API_WORKFLOW_BASE_URL = lodashGet(
    process.env,
    "REACT_APP_API_WORKFLOW_BASE_URL"
  );
  return API_URL || REACT_APP_API_WORKFLOW_BASE_URL;
};

export {
  isTokenValid,
  getJwtToken,
  getBaseUrl,
  getWorkflowBaseUrl,
  getUserData,
};
