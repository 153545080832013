/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import {
  EditOutlined,
  HomeOutlined,
  LineChartOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { useUserData } from "../../hooks";
import { authorizer } from "../../utils/authorizer";
import { getIcon } from "../widgets";

function Sidenav({ color, brandData }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { userData } = useUserData();

  const getMenu = (menu) => {
    if (menu.type === "menuItem") {
      if (!authorizer(userData, menu.page)) {
        return <></>;
      }

      return (
        <Menu.Item key={menu.label}>
          <NavLink to={menu.page}>
            <span className="icon">{getIcon(menu.icon)}</span>
            <span className="label">{menu.label}</span>
          </NavLink>
        </Menu.Item>
      );
    }

    if (menu.type === "subMenu") {
      return (
        <SubMenu
          key={menu.label}
          icon={<span>{getIcon(menu.icon)}</span>}
          title={<span style={{ paddingLeft: 24 }}>{menu.label}</span>}
        >
          {menu.items.map((c) => getMenu(c))}
        </SubMenu>
      );
    }

    if (menu.type === "itemGroup") {
      return (
        <Menu.ItemGroup
          key={menu.label}
          icon={<span className="icon">{getIcon(menu.icon)}</span>}
          title={menu.label}
        >
          {menu.items.map((c) => getMenu(c))}
        </Menu.ItemGroup>
      );
    }
  };

  if (!userData) {
    return <></>;
  }

  return (
    <>
      <div className="brand">
        {brandData.url !== null ? <img src={brandData.url} alt="" /> : ""}
        {brandData.brandType === "NAME" || brandData.brandType === "LOGO" ? (
          <span style={{ paddingLeft: 10 }}>{brandData.companyName}</span>
        ) : (
          ""
        )}
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        {userData.menus.map((menu) => getMenu(menu))}
      </Menu>
    </>
  );
}

export default Sidenav;
