import { get, post } from "./client";

export const getFileMetaList = async ({ queryKey }) => {
  const [key, category] = queryKey;
  const path =(category)?`/${category}`:"";
  console.log(path);
  const res = await get(`/files/meta${path}`);
  return await res.json();
};

export const updateFileUpload = async (request) => {
  const res = await post(`/files/update`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};

export const getSearchableFileCategory = async ({ query, categoryType }) => {
  const response = await get(
    `/categories?q=${query}&categoryType=${categoryType}`
  );
  const categories = await response.json();
  if (categories) {
    return categories.map(({ name }) => ({
      label: name,
      value: name,
    }));
  }
  return [];
};
