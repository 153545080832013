import React from "react";
import { Col, Form } from "antd";

function ScaleFormItem({ children, name, label, rules }) {
  return (
    <Col key="backgroundColor" span={12}>
      <Form.Item name={name} label={label} rules={rules}>
        {children}
      </Form.Item>
    </Col>
  );
}

export default ScaleFormItem;
