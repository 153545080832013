import { Modal } from "antd";
import React, { useState } from "react";
import { AccountBalance } from ".";
import styled from "styled-components";


const ExtraDiv = styled.div`
  background-color: lightblue;
  padding: 3px 0 3px 0;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
`;


export default function AccountBalanceButton({text="View More"}) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal
        title="Account Balances"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={"80VW"}
        style={{ width: "80%", resize: "auto", height: "80VH" }}
      >
        <AccountBalance />
      </Modal>
      <ExtraDiv onClick={()=>setVisible(true)}>{text}</ExtraDiv>
    </>
  );
}
