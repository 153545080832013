import React, { createContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getCompanyBrand } from "../api/accounts";
import { Col, Row, Spin } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";

export const ThemeContext = createContext({
  companyName: "ScaleFi Portal",
  primaryColor: "linear-gradient(#2563a4,#382b93)",
  secondaryColor: "#aed8e6",
  backgroundColor: "#eff0f4",
});

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState({
    companyName: "ScaleFi Portal",
    primaryColor: "linear-gradient(#2563a4,#382b93)",
    secondaryColor: "#aed8e6",
    backgroundColor: "#eff0f4",
  });
  const queryClient = useQueryClient();

  const { refetch, isLoading } = useQuery("theme", getCompanyBrand, {
    onSuccess: (data) => {
      console.log("theme loaded ...", data);
      setTheme(data);
    },
  });

  const reset = () => {
    console.log("resetting ...");
    // queryClient.invalidateQueries("theme");
    refetch.apply();
  };

  const applyTheme = (themeData) => {
    setTheme({ ...theme, ...themeData });
  };

  if (isLoading) {
    return (
      <Row type="flex" align="middle" style={{ alignItems: "center" }}>
        <Col>
          <Spin
            size={"large"}
            style={{
              verticalAlign: "middle",
            }}
          />
        </Col>
      </Row>
    );
  }

  return (
    <ThemeContext.Provider value={{ theme, reset, applyTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
