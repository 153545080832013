import React from "react";
import { Button, Image, Menu, Dropdown } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import map from "lodash/map";
import startWith from "lodash/startsWith";
import styled from "styled-components";
import "./doc-tile.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

const DocTileContainer = ({
  id,
  type,
  fileName,
  relativePath,
  description,
  baseUrl,
}) => {
  const menu = (
    <Menu>
      <Menu.Item key="copy-plain">
        <CopyToClipboard text={`${baseUrl}/files/${relativePath}`}>
          <span>Copy plain Url</span>
        </CopyToClipboard>
      </Menu.Item>
      <Menu.Item key="copy-for-bg">
        <CopyToClipboard text={`url('${baseUrl}/files/${relativePath}')`}>
          <span>Copy For Background Image Url</span>
        </CopyToClipboard>
      </Menu.Item>
    </Menu>
  );
  return (
    <div
      className="thumb-container"
      style={{ width: "200px", minHeight: "200px", marginRight: "1px" }}
    >
      <div
        className="doc-tile"
        style={{ width: "200px", minHeight: "250px", display: "flex" }}
      >
        {startWith(type, "image") ? (
          <div className="image-doc-container">
            <Image
              width={200}
              key={id}
              src={`${baseUrl}/files/${relativePath}`}
            />

            <span
              style={{ fontSize: 13, display: "block", fontWeight: "bold" }}
            >
              {" "}
              {fileName}
            </span>
            <span style={{ fontSize: 13, display: "block" }}>
              {" "}
              {description}
            </span>
          </div>
        ) : (
          <div
            className="other-doc-container"
            style={{ backgroundColor: "lightblue", flexGrow: 1 }}
          >
            <span
              style={{ fontSize: 13, display: "block", fontWeight: "bold" }}
            >
              {" "}
              {fileName}
            </span>
            <span style={{ fontSize: 13, display: "block" }}>
              {" "}
              {description}
            </span>
            <span style={{ fontSize: 13, display: "block" }}> {type}</span>
          </div>
        )}
      </div>
      <div className="button-doc-container">
        <Dropdown overlay={menu} placement="bottomLeft" arrow>
          <Button style={{ width: "100%" }}>Copy Url</Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default DocTileContainer;
