import {Tag} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import React from "react";
import {getBaseUrl, getJwtToken} from "../../utils/auth-util";

const BASE_URL = getBaseUrl();




export const toFileList = (data) => {
    return data.map((item) => {
        const headers = new Headers();
        const token = getJwtToken(); // Replace with your actual authorization token
        headers.append("Authorization", `Bearer ${token}`);
        const downloadUrl = `${BASE_URL}/files/${item.relativePath}`;

        return {
            key: item.id,
            actions: [
                <a
                    key={item.id}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'#'}
                    onClick={(e) => {
                        e.preventDefault();
                        fetch(downloadUrl, { headers })
                            .then((response) => {
                                const fileName = item.fileName+'.'+item.extension;
                                if (response.ok) {
                                    response.blob().then((blob) => {
                                        const url = window.URL.createObjectURL(blob);
                                        const a = document.createElement("a");
                                        a.href = url;
                                        a.download = fileName // Set the desired file name and extension
                                        a.click();
                                        window.URL.revokeObjectURL(url);
                                    });
                                } else {
                                    console.error("Failed to download file");
                                }
                            })
                            .catch((error) => {
                                console.error("Error occurred while downloading file:", error);
                            });
                    }}
                >
                    <Tag color={"lightblue"}>{item.extension}</Tag> <DownloadOutlined />
                </a>,
            ],
            description: item.description,
            name: item.name,
        };
    });
};

/*{
    "id": "ff80808180853810018089e6b1580001",
    "key": "ff80808180853810018089e6b1580001",
    "value": "ff80808180853810018089e6b1580001",
    "label": "Welcome",
    "name": "Welcome",
    "createdAt": "2022-05-03T09:30:38.960+00:00",
    "updatedAt": "2022-05-03T09:30:53.730+00:00",
    "top": 1,
    "type": "NEWS",
    "publish": true
  }*/

export  const toNewsList = (data)=>{
    return data.map((item)=>{
        return {
            key:item.id,
            actions:[
                <a key={item.id} href={`/#/news/${item.id}`}>
                    <Tag color={"lightblue"}>View</Tag>
                </a>,
            ],
            description:item.name,
            name:item.name
        }

    });
}
