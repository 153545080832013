import React from "react";
import { Card, Col, Row, Typography, Skeleton } from "antd";
import { getAccountBalances } from "../../api/accounts";
import styled from "styled-components";
import { format } from "../../utils/money";
import { useQuery } from "react-query";

const AcType = styled.div`
  background-color: lightblue;
  padding: 3px 0 3px 0;
  text-align: center;
  border-radius: 4px;
`;

export default function AccountBalance() {
  const { Title } = Typography;

  const { isLoading, error, data, isFetching } = useQuery(
    "account-balances",
    getAccountBalances
  );

  if (isLoading) return <Skeleton active />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {data.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox " key={c.glNo}>
              <div className="number">
                <Row align="middle" gutter={[24, 0]}>
                  <Col xs={18}>
                    <span>{c.productName}</span>
                    <Title level={3}>
                      {format(c.amount)} <small className={"bnb2"}>{""}</small>
                    </Title>
                  </Col>
                  <Col xs={6}>
                    <AcType>{c.acType}</AcType>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
