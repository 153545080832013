import React, {useState, useEffect} from "react";

import {Modal, Row, Col, Card, Button, Form, Spin} from "antd";
import Search from "antd/lib/transfer/search";
import {RichTextEditor} from "../widgets";
import {ScaleInput} from "../scale-form";
import ScaleAutoCompleteInput from "../scale-form/ScaleAutoCompleteInput";
import {getSearchableFileCategory} from "../../api/files";
import {savePost} from "../../api/posts";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {notifySuccess, notifyWarning} from "../../utils/notify";
import {getPost} from "../../api/posts";

const {useForm} = Form;

const emptyPostData ={
    value: "",
    backgroundType: "Color",
    backgroundColor: "rgba(255,255,255,1)",
    backgroundSize: "auto",
    backgroundImage: null,
    backgroundRepeat: "repeat",
    backgroundPosition: "left top",
};

export default function ContentEditor({
                                          show,
                                          handleShow,
                                          title,
                                          type,
                                          formType,
                                          id,
                                      }) {
    const [form] = useForm();
    const queryClient = useQueryClient();

    const [content, setContent] = useState({
        backgroundColor: '#ffffff',
        value:''
    });

    const [initialized,setInitialized] = useState(false);






    const {isLoading,data:postData} = useQuery(["post", id], getPost, {
        enabled: id != null,
        onSuccess: (data) => {
            setContent(data);
            form.setFieldsValue(data);

        },
    });

    useEffect(()=>{
        if(!show){
            // setContent(null);
            setInitialized(false);
        }

        console.log({initialized,show,content});

        if(show && !initialized && postData){
            setContent(postData);
            setInitialized(true);
        }
    },[initialized,show,postData]);




    const clearForm = () => {

        setContent(emptyPostData);

        form.setFieldsValue({title: "", category: ""});
    };

    const savePostMutation = useMutation(savePost, {
        onSuccess: (data) => {
            notifySuccess("Saved Successfully");
            clearForm();

        },
        onError: (e) => {
            notifyWarning(e.message);
        },
        onSettled: (s) => {
            queryClient.invalidateQueries("posts");
            handleShow(false);
        },
    });

    const onFinish = ({title, category}) => {
        savePostMutation.mutate({id, title, category, ...content});
    };

    const handleContentChange = (value) => {
        setContent(value);
    };

    // if(id != null && isLoading){
    //     return <Spin size={"large"} />;
    // }


    return (
        <>
            <Modal
                title={title}
                centered
                visible={show}
                onOk={() => handleShow(false)}
                onCancel={() => handleShow(false)}
                width={"80VW"}
                style={{width: "100%", resize: "auto", height: "80VH"}}
                okText="Save"
                footer={[
                    <Button
                        form="slideShowForm"
                        type="default"
                        key="cancel"
                        onClick={() => handleShow(false)}
                        htmlType="button"
                    >
                        Cancel
                    </Button>,
                    <Button
                        form="contentCreatorForm"
                        type="primary"
                        key="save"
                        htmlType="submit"
                    >
                        Save
                    </Button>,
                ]}
            >
                <div className="tabled">
                    <Row gutter={[24, 0]}>
                        <Col xs="24" xl={24}>
                            <Card
                                bordered={false}
                                bodyStyle={{paddingTop: 0}}
                                className="header-solid h-full  ant-list-yes"
                                title={<h6 className="font-semibold m-0">Content Editor</h6>}
                                extra={
                                    <Row className="card-header-date">
                                        {/* <Search
                    placeholder="Search by Name | Username"
                    onSearch={onSearch}
                    onChange={debounce(onSearchChange, 1600)}
                    enterButton
                  /> */}

                                    </Row>

                                }
                            >
                                {/* <Row>
                <Col md={18} xl={18} offset={1}>
                  <Button onClick={handleAddNew}>Add New</Button>
                </Col>
              </Row> */}

                                <Form
                                    labelCol={{span: 4}}
                                    wrapperCol={{span: 20}}
                                    autoComplete="off"
                                    onFinish={onFinish}
                                    form={form}
                                    name="contentCreatorForm"
                                    initialValues={content}
                                >
                                    <Row justify="space-between">
                                        <ScaleInput
                                            span={24}
                                            name="title"
                                            label="Title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Title is required",
                                                },
                                            ]}
                                        />
                                        {
                                            !id ? (<ScaleAutoCompleteInput
                                                name="category"
                                                label="Category"
                                                span={24}
                                                allowClear={true}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Category is required",
                                                    },
                                                ]}
                                                fetchOptions={(category) => {
                                                    return getSearchableFileCategory({
                                                        query: category,
                                                        categoryType: "POSTS",
                                                    });
                                                }}
                                                // defaultValue={content.category}
                                            />) : (<ScaleInput
                                                name="category"
                                                label="Category"
                                                span={24}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Category is required",
                                                    },
                                                ]}
                                                // defaultValue={content.category}
                                            />)

                                        }

                                    </Row>
                                </Form>


                                <Row>
                                    <Col md={24} xl={24}>
                                        <RichTextEditor
                                            contentChange={handleContentChange}
                                            content={content}
                                        />
                                    </Col>
                                    {/*         <Col md={24} xl={24}>
                                        <Button
                                            form="contentCreatorForm"
                                            type="primary"
                                            key="save"
                                            htmlType="submit"
                                        >
                                            Save
                                        </Button>
                                    </Col>*/}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </div>

            </Modal>
        </>
    );
}
