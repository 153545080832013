import {ScaleInput} from "../scale-form";
import {Button, Form, message, Upload} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {getBaseUrl, getJwtToken} from "../../utils/auth-util";
import {useTheme} from "../../hooks";

const { useForm } = Form;

export default function FileUpload({span = 24,label,name,form,extraWidth='150px'}) {

    const { theme : {primaryColor,secondaryColor}} = useTheme();

    const onFileChange = (name, info) => {
        const {
            file: { response, type },
        } = info;

        if (response) {
            const {originalFileName,id} = response;
            console.log({originalFileName,id,name})
            let fieldUpdate= {};
            fieldUpdate[`${name}_id`]=id
            fieldUpdate[name]=name
            console.log(fieldUpdate);
            form.setFieldsValue({
                [name]: originalFileName,
                [`${name}_id`]: id,
            });
        }

        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }

    }



    const BASE_URL = getBaseUrl();
    const uploadProps = {
        name: "file",
        action: `${BASE_URL}/files/upload`,
        headers:{ 'Authorization': `Bearer ${getJwtToken()}` }
    };


    return (
        <>
            <ScaleInput
                readOnly={true}
                rules={[{required: true}]}
                key={name}
                name={name}
                label={label}
                span={span}
                extraWidth={extraWidth}
                extra={(<Upload  showUploadList={false} {...uploadProps} onChange={(info)=>{
                    onFileChange(name,info);
                }}>
                    <Button style={{borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0, backgroundColor: primaryColor,color: secondaryColor}} icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>)
                }
            />

            <ScaleInput hidden={true}
                        readOnly={true}
                        key={`${name}_id`}
                        name={`${name}_id`}
                        label={`${label}_id`}
                        span={span}
                        rules={[
                            {
                                required: true,
                                message: "File Id is required",
                            },
                        ]}
            />
        </>
    )
        ;
}
