import {Col, Row, Form, Input, Select, Button, Spin} from "antd";
import React, {useState, useEffect} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ColorPickerButton from "./ColorPickerButton";
import styled from "styled-components";
import {ScaleInput, ScaleFormItem, ScaleSelect} from "../scale-form";
import FileExplorer from "./FileExplorer";
import debounce from "lodash/debounce";

const mapSelectValues = (array) => {
    return array.map((item) => {
        return {value: item, text: item};
    });
};

const backgroundTypeList = mapSelectValues(["Color", "Image"]);
const backgroundSizeList = mapSelectValues([
    "auto",
    "length",
    "cover",
    "contain",
    "initial",
    "inherit",
]);
const backgroundRepeatList = mapSelectValues([
    "repeat",
    "repeat-x",
    "repeat-y",
    "no-repeat",
    "initial",
    "inherit",
]);
const backgroundPositionList = mapSelectValues([
    "left top",
    "left center",
    "left bottom",
    "right top",
    "right center",
    "right bottom",
    "center top",
    "center center",
    "center bottom",
]);

const {useForm} = Form;

const RichEditor = styled(ReactQuill)`
  .ql-editor {
    background-color: ${(props) => props.backgroundColor};
    background-size: ${(props) => props.backgroundSize};
    background-image: ${(props) => props.backgroundImage};
    background-repeat: ${(props) => props.backgroundRepeat};
    background-position: ${(props) => props.backgroundPosition};
    opacity: 0.8;
    margin-top: 20px;
    height: 70vh;
  }
`;

export default function RichTextEditor({contentChange, content,initialContent}) {
    const [form] = useForm();



    const [showFileExplorer, setShowFileExplorer] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);




    const handleShowFileExplorer = (show) => {
        setShowFileExplorer(show);
    };

    const handleBackgroundTypeChange = (value) => {
        if (value === "Color") {
            contentChange({...content, backgroundImage: null});
        }
        contentChange({...content, backgroundType: value});
    };

    const handleBackgroundSizeChange = (value) => {
        contentChange({...content, backgroundSize: value});

    };
    const handleBackgroundImageChange = (value) => {
        contentChange({...content, backgroundImage: value});

    };

    const handleBackgroundColorChange = (value) => {
        contentChange({...content, backgroundColor: value});

    };

    const handleBackgroundRepeatChange = (value) => {
        contentChange({...content, backgroundRepeat: value});

    };

    const handleBackgroundPositionChange = (value) => {
        contentChange({...content, backgroundPosition: value});

    };
    const handleValueChange = (value) => {
        contentChange({...content, value: value});

    };

    const onFinish = (values) => {
        console.log(values);
    };
    console.log("Loaded1");

    if(!content){
        return <Spin size={"large"} />;
    }
    console.log("Loaded2",content);

    return (
        <>
            <Row>
                <Col span={24} md={24}>
                    <RichEditor
                        backgroundColor={content.backgroundColor}
                        backgroundImage={content.backgroundImage}
                        backgroundSize={content.backgroundSize}
                        backgroundRepeat={content.backgroundRepeat}
                        backgroundPosition={content.backgroundPosition}
                        style={{
                            marginTop: 20,
                        }}
                        readOnly={false}
                        theme="snow"
                        value={content.value}
                        onChange={handleValueChange}
                        modules={modules}
                        formats={formats}
                    />
                </Col>
                <FileExplorer
                    title="File Explorer"
                    show={showFileExplorer}
                    handleShow={handleShowFileExplorer}
                />
                <Col
                    span={24}
                    md={24}
                    style={{paddingTop: "40px", marginBottom: "200px"}}
                >
                    <Form
                        labelCol={{span: 8}}
                        wrapperCol={{span: 16}}
                        autoComplete="off"
                        onFinish={onFinish}
                        form={form}
                        name="editorForm"
                    >
                        <Row>
                            <Button onClick={() => handleShowFileExplorer(true)}>
                                Open File Explorer
                            </Button>
                        </Row>
                        <Row justify="space-between">
                            {/* <ScaleInput
                span={12}
                name="title"
                label="Title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                ]}
              /> */}

                            <ScaleSelect
                                span={12}
                                name="backgroundType"
                                label="Background Type"
                                placeholder="Select Background Type"
                                size="large"
                                allowClear
                                data={backgroundTypeList}
                                defaultValue={content.backgroundType}
                                onChange={handleBackgroundTypeChange}
                                rules={[
                                    {
                                        required: true,
                                        message: "Background Type is required",
                                    },
                                ]}
                            />
                            {content.backgroundType === "Image" ? (
                                <>
                                    <ScaleSelect
                                        span={12}
                                        name="backgroundSize"
                                        label="Background Size"
                                        placeholder="Select Background Size"
                                        size="large"
                                        defaultValue={content.backgroundSize}
                                        data={backgroundSizeList}
                                        onChange={handleBackgroundSizeChange}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Background Size is required",
                                            },
                                        ]}
                                    />
                                    <ScaleSelect
                                        span={12}
                                        name="backgroundRepeat"
                                        label="Background Repeat"
                                        placeholder="Select Background Size"
                                        size="large"
                                        defaultValue={content.backgroundRepeat}
                                        data={backgroundRepeatList}
                                        onChange={handleBackgroundRepeatChange}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Background Repeat is required",
                                            },
                                        ]}
                                    />
                                    <ScaleSelect
                                        span={12}
                                        name="backgroundPosition"
                                        label="Background Position"
                                        placeholder="Select Background Size"
                                        size="large"
                                        defaultValue={content.backgroundPosition}
                                        data={backgroundPositionList}
                                        onChange={handleBackgroundPositionChange}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Background Position is required",
                                            },
                                        ]}
                                    />

                                    <ScaleInput
                                        span={12}
                                        name="backgroundImage"
                                        label="Background Image Url"
                                        onChange={(e) => handleBackgroundImageChange(e.target.value)}
                                        defaultValue={content.backgroundImage}
                                    />
                                </>
                            ) : (
                                <ScaleFormItem name="backgroundColor" label="Background Color">
                                    <Input.Group compact>
                                        <ColorPickerButton
                                            show={showColorPicker}
                                            handleShow={setShowColorPicker}
                                            color={content.backgroundColor}
                                            colorPicked={(c) => {
                                                console.log(c);
                                                const {r, g, b, a} = c.rgb;
                                                console.log({r, g, b, a});
                                                handleBackgroundColorChange(`rgba(${r},${g},${b},${a})`);
                                            }}
                                        />
                                    </Input.Group>
                                </ScaleFormItem>
                            )}
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
}

/*
 * Quill modules to attach to editor [011201112508]
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
    toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block", "link", "image", "video"],

        [{header: 1}, {header: 2}], // custom button values
        [{list: "ordered"}, {list: "bullet"}],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{indent: "-1"}, {indent: "+1"}], // outdent/indent
        [{direction: "rtl"}], // text direction

        [{size: ["small", false, "large", "huge"]}], // custom dropdown
        [{header: [1, 2, 3, 4, 5, 6, false]}],

        [{color: []}, {background: []}], // dropdown with defaults from theme
        [{font: []}],
        [{align: []}],
        [{emoji: []}],

        ["clean"], // remove formatting button
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: true,
    },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
    "header",
    "font",
    "size",

    "color",
    "bold",
    "align",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "emoji",
    "clipboard",
];
