import { get, post } from "./client";


export const saveChangePassword = async (request) => {
  const res = await post(`/auth/change-password`, request);
  const { error } = res;
  if (error) {
    throw new Error("Something went wrong" + error);
  }
  const { statusCode, statusDesc, data } = await res.json();

  if (statusCode !== "00") {
    throw new Error(statusDesc);
  }
  return data;
};





