import React from "react";
import { Button, Modal } from "antd";
import { SketchPicker } from "react-color";

function ColorPicker({ show, handleShow, color, colorPicked }) {
  return (
    <>
      <Modal
        title="Color Picker "
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[]}
      >
        <SketchPicker onChangeComplete={(c) => colorPicked(c.hex)} />
      </Modal>
    </>
  );
}

export default ColorPicker;
