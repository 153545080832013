import React, { useState, useMemo, useRef } from "react";
import { AutoComplete } from "antd";
import debounce from "lodash/debounce";

function ScaleAutocomplete({
  fetchOptions,
  onChange,
  debounceTimeout = 800,
  placeholder,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const { max, label, name, rules, onSelectChange, defaultValue } = props;

  const onSelect = (value) => {
    onChange(value);
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      onChange(value);
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <>
      <AutoComplete
        filterOption={false}
        options={options}
        style={{ width: "100%" }}
        onSelect={onSelect}
        onSearch={debounceFetcher}
        placeholder={placeholder}
        defaultValue={defaultValue}
      />
    </>
  );
}

export default ScaleAutocomplete;
