import React, { useState, useMemo, useRef } from "react";

import debounce from "lodash/debounce";
import { Select, Spin, Col, Form, Input } from "antd";
import { notifyWarning } from "../../../utils/notify";

export default function SelectableApprover({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const { max, label, name, step } = props;

  // console.log(value, max);

  // const isMaxValues = value.length === max;
  // const isMaxValues = false;
  const handleShowError = (e) => {
    notifyWarning(
      "Warning",
      `You have selected maximum approvers for this entry deselect to change.`
    );
  };

  const customValidator = (_, value) => {
    if(!value){
      return Promise.reject(`${label} is Required`);

    }
    if(value.length!==max){
      return Promise.reject(`${label} requires ${max} items`);

    }
    return Promise.resolve();
  };
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Col span={24}>
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.approvers !== curValues.approvers
        }
      >
        {({ getFieldValue }) => {
          const value = getFieldValue(name);
          const isMax = value && value.length == max;


          return (
            <Form.Item rules={[{required: true,validator:customValidator}]} name={name} label={`${label} (${max})`}>
              <Select
                labelInValue
                filterOption={false}
                onSearch={debounceFetcher}
                notFoundContent={fetching ? <Spin size="small" /> : null}
                {...props}
                options={options}
                {...(isMax && {
                  open: false,
                  onDropdownVisibleChange: handleShowError,
                })}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </Col>
  );
}
