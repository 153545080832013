import {Card, Table, Tag, Space, Row, Col, Input, Button} from "antd";
import {useQuery} from "react-query";
import {getUsers} from "../api/accounts";

import debounce from "lodash/debounce";

import React, {useState} from "react";
import {ScaleUser} from "../components/widgets";

const {Search} = Input;

export default function UserManagement() {
    const [searchKey, setSearchKey] = useState("");
    const [show, setShow] = useState(false);
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");

    const [userId, setUserId] = useState(null);

    const {data} = useQuery(["users", searchKey], getUsers, {
        initialData: [],
    });

    const onSearch = (value) => {
        setSearchKey(value);
    };

    const onSearchChange = (event) => {
        setSearchKey(event.target.value);
    };

    const handleAddNew = () => {
        setTitle("Add New User");
        setType("ADD");
        handleShow(true);
    };

    const handleEdit = (_userId) => {
        setUserId(_userId);
        setTitle("Edit User");
        setType("EDIT");
        handleShow(true);
    };

    const handleShow = (_show) => {
        setShow(_show);
    };

    const columns = [
        {
            title: "User Name",
            dataIndex: "userId",
            key: "userId",
            render: (text) => <b>{text}</b>,
        },
        {
            title: "Name",
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: "Date Created",
            dataIndex: "dateCreated",
            key: "dateCreated",
        },
        {
            title: "Role",
            key: "roleName",
            dataIndex: "roleName",
            render: (roleName, {labelColor}) => (
                <>
                    <Tag color={labelColor} key={roleName}>
                        {roleName.toUpperCase()}
                    </Tag>
                </>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => handleEdit(record.userId)}>Edit</Button>
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{paddingTop: 0}}
                            className="header-solid h-full  ant-list-yes"
                            title={<h6 className="font-semibold m-0">User Administration</h6>}
                            extra={
                                <Row className="card-header-date">
                                    <Search
                                        placeholder="Search by Name | Username"
                                        onSearch={onSearch}
                                        onChange={debounce(onSearchChange, 1600)}
                                        enterButton
                                    />
                                </Row>
                            }
                        >
                            <Row>
                                <Col md={18} xl={18} offset={1}>
                                    <Button onClick={handleAddNew}>Add New</Button>
                                </Col>
                            </Row>
                            {show ?
                                (<ScaleUser
                                    show={show}
                                    handleShow={handleShow}
                                    title={title}
                                    type={type}
                                    userId={userId}
                                />) : ""}
                            <Table columns={columns} dataSource={data}/>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    );
}
