import React, {useState} from "react";
import {useQuery} from "react-query";
import {Card, Typography, Spin, List, Avatar} from "antd";
import {getLoanStatus} from "../../api/accounts";
import moment from "moment/moment";
import {formatAmount} from "../../utils/money";
import {useTheme} from "../../hooks";
import LoanStatusModal from "./LoanStatusModal";


const LoanStatus = () => {
const {theme: {primaryColor}} = useTheme();

    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const {isLoading, error, data: transactions} = useQuery(
        "loan-transactions-status",
        getLoanStatus,
        {
            initialData: []
        }
    );

    const openModal = (transaction) => {
        setSelectedTransaction(transaction);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    if (isLoading || error) {
        return <Spin/>;
    }


    return (
        <Card
            bordered={false}
            bodyStyle={{paddingTop: 0,paddingBottom: 50}}
            className="header-solid h-full  ant-list-yes"
            title={<h6 className="font-semibold m-0">Application Status</h6>}
            extra={
                <p className="card-header-date">
                    <span></span>
                </p>
            }
        >
            <List
                className="yestday transactions-list"
                header={<h6>Recent Loan Applications</h6>}
                itemLayout="horizontal"
                dataSource={transactions}
                renderItem={(item) => (
                    <List.Item onClick={() => openModal(item)} style={{ cursor: "pointer" }}>
                        <List.Item.Meta
                            avatar={
                                <Avatar size="small" style={{backgroundColor: item.statusColor}} className={"text-success"}>
                                    {""}
                                </Avatar>
                            }
                            title={item.productName}
                            description={`${item.loanStatus} (${moment(item.appliDate).format(
                                "DD MMM, yyyy ddd"
                            )})`}
                        />
                        <div className="amount">
            <span className={"text-success" } style={{color: primaryColor}}>
              {formatAmount(Number(item.loanAmt))}
            </span>
                        </div>
                    </List.Item>
                )}
            />
            <LoanStatusModal
                transaction={selectedTransaction}
                modalVisible={modalVisible}
                handleModalClose={handleModalClose}
            />
        </Card>
    );
};

export default LoanStatus;
