import React, { createContext, useState } from "react";
import { getItem, setItem } from "../utils/storage";

export const UserContext = createContext(getItem("userData"));

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDatails] = useState(getItem("userData"));

  return (
    <UserContext.Provider
      value={{
        userData: userDetails,
        setUserData: (userData) => {
          setItem("userData", userData);
          setUserDatails(userData);
        },
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
