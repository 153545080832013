import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Select, Form, Input, Button, Tag, Radio } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { searchPublications, saveSlideConfig } from "../../api/posts";

import SelectAutocomplete from "./event-guide/SelectAutocomplete";
import { notifySuccess, notifyWarning } from "../../utils/notify";
import { ScaleSelect } from "../scale-form";
const { useForm } = Form;

const options = [
  { value: "LOGIN_PAGE", label: "Login Page" },
  { value: "HOME_PAGE", label: "Home Page" },
];

export default function SetSlideShow({ show, handleShow, title }) {
  const queryClient = useQueryClient();

  const [form] = useForm();

  const clearForm = () => {
    handleShow(false);
  };

  const saveMutation = useMutation(saveSlideConfig, {
    onSuccess: (data) => {
      notifySuccess("Saved Successfully");
      clearForm();
    },
    onError: (e) => {
      notifyWarning(e.message);
      console.log("error", e);
    },
    onSettled: (s) => {
      queryClient.clear("users");
    },
  });

  const onFinish = ({ id, publication }) => {
    const [selected] = publication;
    saveMutation.mutate({ id, publicationId: selected.value });
  };

  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"60VW"}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[
          <Button
            form="setSlideForm"
            type="default"
            key="cancel"
            onClick={() => handleShow(false)}
            htmlType="button"
          >
            Cancel
          </Button>,
          <Button
            form="setSlideForm"
            type="primary"
            key="save"
            htmlType="submit"
          >
            Save
          </Button>,
        ]}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          name="setSlideForm"
        >
          <Row justify="space-between">
            <ScaleSelect
              name="id"
              label="Slide Page"
              options={options}
              span={24}
              rules={[
                {
                  required: true,
                  message: "Slide Page is required",
                },
              ]}
            />
            <SelectAutocomplete
              span={24}
              mode="multiple"
              label={`Slide Show`}
              placeholder={`Search and select slide show`}
              name="publication"
              max={1}
              fetchOptions={(name) => {
                return searchPublications({
                  queryKey: [
                    "publications",
                    {
                      query: name,
                      type: "SLIDE",
                      category: "POSTS",
                    },
                  ],
                });
              }}
              style={{
                width: "100%",
              }}
              rules={[
                {
                  required: true,
                  message: "Slide Show are required",
                },
              ]}
            />
          </Row>
        </Form>
      </Modal>
    </>
  );
}
