import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Select, Form, Input, Button, Tag, Radio } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { notifySuccess, notifyWarning } from "../../utils/notify";
import { useAuth ,useUserData} from "../../hooks";
import {saveChangePassword} from "../../api/auth";
import {ScaleInput} from "../scale-form";
const { useForm } = Form;



export default function ChangePassword({
  show,
  handleShow,
  title
}) {
  const queryClient = useQueryClient();

    const { setAuthenticated } = useAuth();
    const {setUserData} = useUserData();


    const [form] = useForm();
    const changePasswordMutation = useMutation(saveChangePassword, {
        onSuccess: (data) => {
            notifySuccess("Saved Successfully");
            logout();
        },
        onError: (e) => {
            notifyWarning(e.message);
            console.log("error", e);
        },
        onSettled: (s) => {
           // queryClient.invalidateQueries("users");
        },
    });




    const logout = () => {
        setAuthenticated(false);
        setUserData(null);
  };



  const onFinish = ({ currentPassword, newPassword, newPassword2 }) => {
      if(newPassword !== newPassword2){
          notifyWarning("New Passwords should match");
          return;
      }
      if(currentPassword === newPassword){
          notifyWarning("You can not repeat old password");
          return;
      }
      changePasswordMutation.mutate({ currentPassword, newPassword, newPassword2 });
      console.log({ currentPassword, newPassword, newPassword2 })
  };



  return (
    <>
      <Modal
        title={title}
        centered
        visible={show}
        onOk={() => handleShow(false)}
        onCancel={() => handleShow(false)}
        width={"60VW"}
        style={{ width: "100%", resize: "auto", height: "80VH" }}
        okText="Save"
        footer={[
          <Button
            form="changePasswordForm"
            type="default"
            key="cancel"
            onClick={logout}
            htmlType="button"
          >
            Logout
          </Button>,
          <Button
            form="changePasswordForm"
            type="primary"
            key="save"
            htmlType="submit"
          >
            Change Password
          </Button>,
        ]}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          onFinish={onFinish}
          form={form}
          name="changePasswordForm"
        >
          <Row justify="space-between">
            <ScaleInput
                type={"password"}
              span={24}
              rules={[
                {
                  required: true,
                  message: "Current Password is required" ,
                },
              ]}
              name="currentPassword"
              label="Current Password"
            />
            <ScaleInput
                type={"password"}
                span={24}
                rules={[
                  {
                    required: true,
                    message: "New Password is required",
                  },
                ]}
                name="newPassword"
                label="New Password"
            />
            <ScaleInput
                type={"password"}
                span={24}
                rules={[
                  {
                    required: true,
                    message: "Repeat New Password is required",
                  },
                ]}
                name="newPassword2"
                label="Repeat New Password"
            />
          </Row>
        </Form>
      </Modal>
    </>
  );
}
