import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";
import { ChangePassword } from "../widgets";
import { useUserData,useTheme } from "../../hooks";
import styled from "styled-components";


const { Header: AntHeader, Content, Sider } = Layout;


const MainLayout = styled(Layout)`
  .ant-layout {
    background-color:${(props => props.backgroundColor)}
  }
`


function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("transparent");
  const {theme} =useTheme();


  const location = useLocation();
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    if (visible) {
      openDrawer();
    }
  }, [location]);


  const openDrawer = () => setVisible(!visible);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);


  const { userData } = useUserData();


  return (
    <MainLayout backgroundcolor={theme.backgroundColor} className={`layout-dashboard`} >
      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        key={placement === "right" ? "left" : "right"}
        width={290}
        className={`drawer-sidebar`}
      >
        <MainLayout className={`layout-dashboard`} backgroundcolor={theme.backgroundColor}>
          <Sider
            trigger={null}
            width={290}
            theme="dark"
            className={`sider-primary ant-layout-sider-primary`}
          >
            <Sidenav color={sidenavColor} brandData={theme} />
          </Sider>
        </MainLayout>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth="0"
        onCollapse={(collapsed, type) => {}}
        trigger={null}
        width={290}
        theme="light"
        className={`sider-primary ant-layout-sider-primary`}
        style={{ background: theme.primaryColor }}
      >
        <Sidenav color={sidenavColor} brandData={theme} />
      </Sider>
      <Layout>
        {fixed ? (
          <Affix>
            <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
              <Header onPress={openDrawer} name={""} subName={""} />
            </AntHeader>
          </Affix>
        ) : (
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
              name={""}
              subName={""}
              handleSidenavColor={handleSidenavColor}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
        )}
        <Content className="content-ant">{children}</Content>
        <ChangePassword
          title={"ChangePassword"}
          show={userData && userData.changePassword}
          handleShow={() => {}}
        />

        <Footer backgroundColor={theme.backgroundColor} />
      </Layout>
    </MainLayout>
  );
}

export default Main;
